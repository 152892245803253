/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-escape */

import { User } from "api/types";
import Button from "components/Button";
import ForgotPassword from "components/ForgotPassword";
import { Icon } from "components/Icon";
import Input from "components/Input";
import Notification from "components/Notification";
import { useState } from "react";
import { MyAccountStepNames } from ".";
import {
  getCodeOfConductData,
  getContactData,
  getContractData,
  getEmergencyContactData,
  getEssentialDocumentData,
  getPersonalInformationData,
  getTrainingData,
} from "./fields";

export const usePersonalDetails = (
  user: User,
  onEditClick?: (stepName: MyAccountStepNames) => void
) => {
  const [readMoreCodeOfConduct, setReadMoreCodeOfConduct] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  if (!user) {
    return [];
  }
  return [
    {
      title: "Personal details",
      data: getPersonalInformationData(user),
      sectionId: "PersonalInfo",
      parentContainerClassName: "!mt-0",
    },
    {
      title: "Contact details",
      data: getContactData(user),
      footer: (
        <Button
          variant="primary"
          onClick={() =>
            onEditClick && onEditClick(MyAccountStepNames.CONTACT_DETAILS)
          }
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
      sectionId: "ContactDetails",
    },
    {
      title: "Emergency contact detail",
      data: getEmergencyContactData(user.staffProfile?.emergencyContact),
      footer: (
        <Button
          variant="primary"
          onClick={() =>
            onEditClick && onEditClick(MyAccountStepNames.EMERGENCY_CONTACT)
          }
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
      sectionId: "EmergencyContactDetail",
    },
    {
      title: "Essential Documents",
      data: getEssentialDocumentData(user),
      heading: (
        <Notification
          type="info"
          message="Organise these documents beforehand so that you don’t lose access to your staff account when the documents expire."
          className="!m-0"
        />
      ),
      sectionId: "EssentialDocuments",
    },
    {
      title: "Training and qualifications",
      data: getTrainingData(user.staffProfile),
      footer: (
        <Button
          variant="primary"
          onClick={() =>
            onEditClick && onEditClick(MyAccountStepNames.TRAINING)
          }
        >
          Edit
        </Button>
      ),
      footerClassName: "justify-end",
      sectionId: "TrainingAndQualifications",
    },
    {
      title: "Code of conduct",
      heading: (
        <div>
          <p className="text-sm leading-5 font-normal text-gray-700 mb-3">
            EICARE is committed to ensuring staffs’ behaviour is appropriate in
            the work environment and demonstrates our five core values
            Empowerment, independent, integrity, transparency and openness.
          </p>
          {!readMoreCodeOfConduct && (
            <Button
              variant="text"
              onClick={() => setReadMoreCodeOfConduct(!readMoreCodeOfConduct)}
            >
              <Icon name="plus" className="mr-2" />
              <span>Read more</span>
            </Button>
          )}
          {readMoreCodeOfConduct && (
            <div className="text-sm leading-5 font-normal text-gray-700">
              <p className="font-semibold">
                This Code of Conduct requires all staff members at all times to:
              </p>
              <ul className="list-disc list-inside">
                <li>Abide by the philosophy of EICARE Home Care</li>
                <li>
                  Observe all the rules of EICARE Home Care including those
                  specified in the constitution and any others determined by the
                  Board
                </li>
                <li>
                  Adhere to all the accounting procedures of EICARE Home Care
                </li>
                <li>
                  Work in a safe and competent manner in accordance with the
                  policies and procedures of EICARE Home Care
                </li>
                <li>
                  Respect the dignity and culture, values and beliefs of all
                  individuals
                </li>
                <li>Represent EICARE Home Care in a positive way</li>
                <li>
                  Wear suitable clothing including closed in shoes, clean and
                  discrete shirt and trousers (or shorts)
                </li>
                <li>
                  Not discuss confidential issues of EICARE Home Care with
                  people outside the organisation, regard all information
                  provided to them by a consumer as confidential and never
                  disclose personal information to a consumer
                </li>
                <li>
                  Not take illegal drugs or consume alcohol when on duty or on
                  the premises
                </li>
                <li>
                  Not accept gifts or purchase any items from consumers (except
                  home garden produce)
                </li>
                <li>
                  Not have sexual relationships with consumers or take them to
                  their (staff) homes or engage in a relationship with consumers
                  outside of a professional relationship
                </li>
                <li>
                  Follow any grievance procedures set down by the Board to try
                  to resolve any conflicts with other staff or members of EICARE
                  Home Care
                </li>
                <li>
                  Not harass in any form consumers, other staff or members of
                  EICARE Home Care
                </li>
                <li>
                  Not abuse, physically or verbally, consumers, other staff or
                  members of EICARE Home Care
                </li>
                <li>
                  Not give advice to consumers or diagnose the condition of a
                  consumer’s health (except health promotion advice provided by
                  a Registered Nurse). If requested by the family as to your
                  thoughts regarding the condition of a consumer – refer them to
                  their GP or hospital
                </li>
                <li>Not alienate consumers from their family</li>
                <li>
                  Treat consumers with courtesy, respect and consideration, act
                  on complaints and provide services to the best of their
                  ability
                </li>
              </ul>
              <p className="my-4">Commonwealth aged care code of conduct</p>
              <p className="font-medium">
                When providing care, supports and services to people, I must:
              </p>
              <ul className="mb-4">
                <li>
                  (a) act with respect for people’s rights to freedom of
                  expression, self-determination and decision-making in
                  accordance with applicable laws and conventions; and
                </li>
                <li>
                  (b) act in a way that treats people with dignity and respect,
                  and values their diversity; and
                </li>
                <li>(c) act with respect for the privacy of people; and</li>
                <li>
                  (d) provide care, supports and services in a safe and
                  competent manner, with care and skill; and
                </li>
                <li>(e) act with integrity, honesty and transparency; and</li>
                <li>
                  (f) promptly take steps to raise and act on concerns about
                  matters that may impact the quality and safety of care,
                  supports and services; and
                </li>
                <li>
                  (g) provide care, supports and services free from:
                  <ul className="">
                    <li>
                      (i) all forms of violence, discrimination, exploitation,
                      neglect and abuse; and
                    </li>
                    <li>(ii) sexual misconduct; and</li>
                  </ul>
                </li>
                <li>
                  (h) take all reasonable steps to prevent and respond to:
                  <ul className="">
                    <li>
                      (i) all forms of violence, discrimination, exploitation,
                      neglect and abuse; and
                    </li>
                    <li>(ii) sexual misconduct.</li>
                  </ul>
                </li>
              </ul>
              <Button
                variant="text"
                onClick={() => setReadMoreCodeOfConduct(!readMoreCodeOfConduct)}
              >
                <Icon name="solidMinus" className="mr-2" />
                <span>Read less</span>
              </Button>
            </div>
          )}
        </div>
      ),
      data: getCodeOfConductData(user.staffProfile),
      sectionId: "CodeOfConduct",
    },
    {
      title: "Contract",
      data: getContractData(user.staffProfile),
      sectionId: "Contract",
    },
    {
      title: "Change Password",
      heading: (
        <>
          <Input
            label="Password"
            type="password"
            value="*********"
            placeholder="*********"
            onChange={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
            disabled
            labelClassName="mt-5"
          />
          <ForgotPassword
            openDialogue={openDialogue}
            setOpenDialogue={setOpenDialogue}
            defaultValue={user.email}
            shouldDisableInput
          />
        </>
      ),
      data: [],
      footer: (
        <Button variant="primary" onClick={() => setOpenDialogue(true)}>
          Update password
        </Button>
      ),
      footerClassName: "justify-end",
      sectionId: "ChangePassword",
    },
  ];
};
