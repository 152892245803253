import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { get } from "utils/util";
import Badge from "./Badge";
import { Icon } from "./Icon";
import { FormField } from "./types";
import { renderErrorMessage } from "./utility";

type Option = {
  label: string;
  value: string | number;
};

type GroupedOptions = {
  title: string;
  options: Option[];
};

interface Props extends FormField {
  name: string;
  inputClassName?: string;
  containerClassName?: string;
  error?: FieldError | undefined;
  isClearable?: boolean;
  value?: string | number | string[];
  onChange?: Function;
  hideSearch?: boolean;
  groupedOptions?: GroupedOptions[];
  inputContainerClassName?: string;
}

const GroupedMultiselect: React.FC<Props> = ({
  name,
  groupedOptions,
  placeholder = "Select",
  error,
  inputClassName,
  containerClassName,
  label,
  defaultValue,
  isDisabled,
  requiredCondition,
  isClearable,
  value,
  onChange,
  className,
  hideSearch = false,
  isHiglighted,
  inputContainerClassName,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openUpwards, setOpenUpwards] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [hiddenCount, setHiddenCount] = useState(0);
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext();

  const stateValue = watch(name);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const initializeSelectedOptions = () => {
      const allOptions = groupedOptions
        ? groupedOptions.flatMap((group) => group.options)
        : [];

      if (defaultValue && allOptions.length > 0) {
        const selectedOptions = allOptions.filter((option) =>
          (defaultValue as string[]).includes(option.value as string)
        );
        setSelectedOptions([...selectedOptions]);
        setValue(
          name,
          selectedOptions.map((opt) => opt.value)
        );
      }
    };

    initializeSelectedOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    if (isOpen && dropdownRef.current && selectRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const selectRect = selectRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      const spaceBelow = viewportHeight - selectRect.bottom;
      const spaceAbove = selectRect.top;

      if (dropdownRect.height > spaceBelow - 100 && spaceAbove > spaceBelow) {
        setOpenUpwards(true);
      } else {
        setOpenUpwards(false);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      // const containerWidth = containerRef.current.offsetWidth;
      const items = Array.from(container.children) as HTMLElement[];
      let totalWidth = 20;
      let count = 0;
      for (const item of items) {
        totalWidth += item.clientWidth;
        if (totalWidth > container.clientWidth) break;
        count++;
      }
      setHiddenCount(selectedOptions.length - count);
    }
  }, [selectedOptions]);

  if (!groupedOptions) {
    return <></>;
  }

  const handleToggle = () => {
    !isDisabled && setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    const index = selectedOptions.findIndex(
      (selectedOption) => selectedOption.value === option.value
    );
    if (index === -1) {
      setSelectedOptions([...selectedOptions, option]);
      setValue(
        name,
        [...selectedOptions, option].map((lang) => lang.value)
      );
    } else {
      const updatedSelectedOptions = [...selectedOptions];
      updatedSelectedOptions.splice(index, 1);
      setSelectedOptions(updatedSelectedOptions);
      setValue(
        name,
        updatedSelectedOptions.map((lang) => lang.value)
      );
    }
    trigger(name);
    setSearchTerm("");
    onChange && onChange(option);
  };

  const filterOptions = (options: Option[]) => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const isSelected = (option: Option) => {
    return selectedOptions.some(
      (selectedOption) => selectedOption.value === option.value
    );
  };

  return (
    <div className={classNames("mt-5", containerClassName || "w-96")}>
      {label && (
        <label
          htmlFor={name}
          className={classNames(
            "block text-sm font-semibold text-gray-700",
            isDisabled && "!text-gray-400"
          )}
        >
          {label}
        </label>
      )}
      <div className="relative mt-1" ref={selectRef}>
        <div
          className={classNames(
            "flex items-center cursor-pointer justify-between",
            "h-10 text-gray-900 rounded-md border-0 px-3 py-2 mt-1 shadow-sm ring-1 ring-inset placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-400 focus:shadow sm:text-sm sm:leading-6",
            isDisabled && "!text-gray-400 !border-gray-300",
            "active:ring-2 active:ring-inset active:ring-primary-400 active:shadow",
            isOpen ? "ring-2 !ring-primary-400 shadow" : "ring-gray-300",
            {
              "!ring-red-600": get(errors, name),
              "!focus:ring-red-600": get(errors, name),
            },
            className,
            "bg-white",
            isHiglighted &&
              requiredCondition &&
              !stateValue &&
              "ring-secondary-500"
          )}
          onClick={handleToggle}
        >
          <div
            className={classNames(
              "overflow-hidden max-h-40 w-full flex-nowrap flex space-x-1 text-ellipsis",
              inputContainerClassName
            )}
            ref={containerRef}
          >
            {selectedOptions.length > 0 ? (
              <>
                {selectedOptions.map((item, index) => (
                  <div
                    key={index}
                    className="overflow-hidden text-ellipsis flex-shrink-0 w-auto"
                  >
                    {item.label}
                  </div>
                ))}
                {hiddenCount > 0 && (
                  <div
                    className="absolute inset-y-0 right-0 pr-5 flex items-center text-gray-500"
                    data-tooltip-id="selectedOptions"
                    data-tooltip-content={`${selectedOptions
                      .map((option) => option.label)
                      .join(", ")}`}
                  >
                    <Badge
                      message={`+${hiddenCount}`}
                      className="bg-primary-100 text-primary-900 text-sx"
                    />
                    <Tooltip id="selectedOptions" />
                  </div>
                )}
              </>
            ) : (
              placeholder
            )}
          </div>
          {isOpen ? (
            <Icon
              name="downChevron"
              className="animate rotate-180 text-gray-500"
              size={20}
            />
          ) : (
            <Icon
              name="downChevron"
              className={classNames(
                "text-gray-500",
                isDisabled && "!text-gray-400 !border-gray-300"
              )}
              size={20}
            />
          )}
        </div>
        {isOpen && (
          <div
            className={classNames(
              "absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg min-w-80",
              openUpwards ? "bottom-full mb-1" : "top-full mt-1",
              className
            )}
            ref={dropdownRef}
          >
            {!hideSearch && (
              <div className="relative flex items-center p-2">
                <input
                  type="text"
                  className={classNames(
                    "flex-1 h-10 text-gray-900 rounded-md border-0 pr-3 pl-9 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-primary-400 focus:shadow sm:text-sm sm:leading-6 disabled:text-gray-400 disabled:border-gray-300",
                    inputClassName,
                    "py-2"
                  )}
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="absolute inset-y-0 left-3 pl-3 flex items-center pointer-events-none">
                  <Icon name="search" />
                </div>
              </div>
            )}
            <div className="max-h-60 overflow-y-auto">
              {groupedOptions &&
                groupedOptions.map((group) => (
                  <div key={group.title}>
                    {group.title && (
                      <div className="px-4 py-2 text-gray-900 text-sm">
                        {group.title}
                      </div>
                    )}
                    {filterOptions(group.options).map((option) => (
                      <div
                        key={option.value}
                        className={classNames(
                          `group flex justify-between items-center cursor-pointer hover:bg-primary-200 focus:bg-primary-400 px-4 py-2 text-gray-900 text-sm leading-5 disabled:bg-gray-50 disabled:text-gray-400 ${isSelected(option) ? "font-semibold" : "font-normal"}`
                        )}
                        onClick={() => handleOptionClick(option)}
                      >
                        <span>
                          {group.title && <span className="mx-3">-</span>}
                          {option.label}
                        </span>
                        {isSelected(option) && (
                          <Icon
                            name="solidCheck"
                            className="text-primary-500 group-hover:text-gray-900 hover:text-gray-900"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        )}
        <Controller
          name={name}
          control={control}
          rules={{ required: requiredCondition }}
          render={({ field }) => (
            <input
              type="hidden"
              {...field}
              value={(stateValue || []).map((option: string) => option)}
            />
          )}
        />
      </div>
      {renderErrorMessage(get(errors, name) as FieldError)}
    </div>
  );
};

export default GroupedMultiselect;
