import { FailureReasons, Staff } from "api/types";
import { useGetStaff } from "api/user";
import classNames from "classnames";
import Badge from "components/Badge";
import Button from "components/Button";
import GroupedMultiselect from "components/GroupedMultiselect";
import { Icon } from "components/Icon";
import { Loading } from "components/Loading";
import MultiSelectDropdown from "components/MultiSelect";
import Notification from "components/Notification";
import Pagination from "components/Pagination";
import StaffDetail from "components/StaffDetail";
import Input from "components/UnControlledInput";
import { FieldType, FormData } from "components/types";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  ResidencyOptions,
  VisaTypeOptions,
} from "routes/onboarding/staff/steps/step1";
import {
  cleanObject,
  countriesOptions,
  enumToObjects,
  serializeParams,
} from "utils/util";
import { DashboardStaffOutletContext } from ".";
import { OccupationOptions } from "./InviteStaff";

export interface StaffFilters {
  filter_country_of_birth: string;
  filter_visa_type: string[];
  filter_occupation: string;
  filter_residency: string;
  filter_status: string[];
  [k: string]: string | string[] | boolean;
}

export default function ArchivedStaff() {
  const { isAdmin } = useOutletContext<DashboardStaffOutletContext>();
  const [staff, setStaff] = useState<Staff[]>([]);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const { handleSubmit, setValue, getValues, watch, reset, ...formMethods } =
    useForm<FormData>();

  const defaultFilter: Partial<StaffFilters> = {
    filter_status: undefined,
    filter_visa_type: undefined,
    filter_country_of_birth: undefined,
    filter_residency: undefined,
    filter_occupation: undefined,
    search: undefined,
    take: "10",
    page: "1",
  };
  const [filters, setFilters] = useState({ ...defaultFilter });
  const { data, isFetching } = useGetStaff(
    isAdmin,
    cleanObject({
      ...filters,
      archived_status: "archived",
    })
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    if (data) {
      if (Array.isArray(data.staffs) && data.staffs) {
        setStaff(data.staffs);
        setTotalItems(data.count);
        return;
      }
      setIsError(true);
    }
  }, [data, isFetching]);

  const onSubmit = async (data: FormData) => {
    data["page"] = "1";
    data["take"] = itemsPerPage;
    delete data.itemPerPage;
    setFilters({ ...data });
    setCurrentPage(1);
  };
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilters({ ...filters, page: page.toString() });
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setFilters({ ...filters, page: (currentPage + 1).toString() });
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setFilters({ ...filters, page: (currentPage - 1).toString() });
  };

  const getBadge = (message: string, key: string) => {
    if (typeof message === "boolean" || key === "archived") {
      return;
    }
    return (
      <Badge
        message={(message || "").split("_").join(" ")}
        key={message}
        iconName="close"
        size={16}
        className="bg-gray-100 mt-3 space-x-2"
        statusClassName="!text-gray-800 !text-sm leading-5 font-medium"
        isIconOnRight
        iconClick={() => {
          if (key === "search") {
            setValue("search", "");
          }
          const queryParams = serializeParams(filters);
          queryClient.invalidateQueries([`/api/user/admin/staff`, queryParams]);
          const orgValues = filters[key];
          if (
            Array.isArray(orgValues) &&
            orgValues.length >= 2 &&
            orgValues.indexOf(message) > -1
          ) {
            const updatedArray = [
              ...orgValues.filter((msg) => msg !== message),
            ];
            setFilters({
              ...filters,
              [key]: updatedArray,
            });
            return;
          }
          setFilters({ ...filters, [key]: undefined });
          setValue(key, undefined);
        }}
      />
    );
  };

  const getStatusOptions = () => {
    const failureReasonOptions = enumToObjects(FailureReasons).map(
      (option) => ({
        label: option.label.split("_").join(" "),
        value: option.value,
      })
    );
    const failureReason = {
      title: "Failure reason",
      options: failureReasonOptions,
    };
    return [failureReason];
  };

  const getActiveFilterValues = () => {
    delete filters.archived;
    const filterValues = Object.keys(
      cleanObject({
        ...filters,
        page: undefined,
        take: undefined,
        itemPerPage: undefined,
      })
    );
    if (filterValues.length > 0) {
      return (
        <div className="flex justify-between items-start mt-3">
          <div className="flex items-center flex-wrap space-x-3 w-3/4">
            <span className="text-gray-900 text-sm mt-3 font-semibold">
              Filtered by:
            </span>
            {filterValues.map((key) => {
              const message = filters[key];
              return Array.isArray(message)
                ? message.map((msg) => getBadge(msg, key))
                : getBadge((message as string) || "", key);
            })}
          </div>
        </div>
      );
    }
    return <></>;
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page whenever items per page change
    setFilters({ ...filters, page: "1", take: newItemsPerPage.toString() });
  };

  const getNoResult = () => {
    return (
      <div className="px-6 py-14 flex flex-col items-center justify-center">
        <div>
          <Icon name="search" className="text-primary-500" size={52} />
        </div>
        <div className="mt-11 space-y-3 flex flex-col items-center justify-center mb-6">
          <span className="text-2xl leading-8 font-semibold text-gray-900">
            Sorry! No results found.
          </span>
          <span className="text-base leading-6 font-medium text-gray-500 text-center">
            We can't find any results matching your search. Try changing
            selected filters.
          </span>
        </div>
        <Button
          variant="white"
          className="flex items-center"
          onClick={() => {
            setValue("search", "");
            setValue("filter_home_region", "");
            reset();
            setFilters({ ...defaultFilter });
            queryClient.invalidateQueries([`/api/user/admin/staff`]);
            setCurrentPage(1);
          }}
        >
          <Icon name="close" />
          <span className="ml-2">Clear all filters</span>
        </Button>
      </div>
    );
  };

  if (!isAdmin) {
    return <></>;
  }
  if (isError) {
    return (
      <Notification
        type="error"
        message={"Error in fetching data from server"}
      />
    );
  }

  if (!data || isFetching) {
    return <Loading />;
  }
  return (
    <div>
      <header className="mx-auto max-w-7xl">
        <div className="font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 pt-10 pb-3 flex justify-between">
          <h1 className="text-3xl flex-1">Archived accounts</h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl pt-8">
          <div className="flex items-center justify-end mb-6">
            <span className="text-gray-500 text-sm">
              {`Showing ${currentPage} to ${
                currentPage * itemsPerPage
              } of ${totalItems} results`}
            </span>
          </div>
          <FormProvider
            {...{
              ...formMethods,
              handleSubmit,
              setValue,
              getValues,
              reset,
              watch,
            }}
          >
            <form
              className="!border-0"
              id={"filter"}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                className={classNames(
                  "flex items-center space-x-3 justify-between"
                )}
              >
                <div className="flex items-center space-x-4 flex-1">
                  <Input
                    name="search"
                    label=""
                    type={FieldType.Input}
                    containerClassName="relative !m-0 w-3/4"
                    placeholder="Search firstname, lastname, email, phone number"
                    className="pl-10"
                    defaultValue={filters.search}
                  >
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <Icon name="search" />
                    </div>
                  </Input>
                  {!showAllFilters && (
                    <Button
                      variant="primary"
                      size="base"
                      className="space-x-2 flex justify-center items-center !px-11"
                      onClick={() =>
                        setFilters({ ...filters, search: getValues("search") })
                      }
                    >
                      <Icon name="search" className="text-gray-900" />
                      <span>Search</span>
                    </Button>
                  )}
                </div>

                {!showAllFilters && (
                  <Button
                    variant="white"
                    className="space-x-3"
                    onClick={() => setShowAllFilters(true)}
                  >
                    <Icon name="filter" />
                    <span>Show Filters</span>
                  </Button>
                )}
                {showAllFilters && (
                  <Button
                    variant="white"
                    className={classNames(
                      "space-x-3",
                      " outline !outline-primary-500 outline-offset-2"
                    )}
                    onClick={() => setShowAllFilters(false)}
                  >
                    <Icon name="filter" />
                    <span>Hide Filters</span>
                  </Button>
                )}
              </div>
              {showAllFilters && (
                <div>
                  <div className="flex items-center space-x-1 md:space-x-5 flex-wrap lg:flex-nowrap">
                    <GroupedMultiselect
                      name="filter_status"
                      type={FieldType.MultiSelect}
                      groupedOptions={getStatusOptions()}
                      label="Profile status"
                      className="bg-white w-full max-w-full overflow-hidden"
                      isClearable={true}
                      defaultValue={filters.filter_status}
                      containerClassName="w-1/3 max-w-[32.33%]"
                      inputContainerClassName="w-[90%]"
                    />
                    <MultiSelectDropdown
                      name="filter_visa_type"
                      type={FieldType.MultiSelect}
                      options={VisaTypeOptions}
                      label="Visa type"
                      isClearable={true}
                      defaultValue={filters.filter_visa_type}
                      className="bg-white"
                      containerClassName="w-1/3 max-w-[32.33%]"
                      inputContainerClassName="w-[90%]"
                    />
                    <MultiSelectDropdown
                      name="filter_country_of_birth"
                      type={FieldType.MultiSelect}
                      options={countriesOptions}
                      label="Country of Birth"
                      isClearable={true}
                      defaultValue={filters.filter_country_of_birth}
                      className="bg-white"
                      containerClassName="w-1/3 max-w-[32.33%]"
                      inputContainerClassName="w-[90%]"
                    />
                  </div>
                  <div className="flex items-center space-x-1 md:space-x-5 flex-wrap lg:flex-nowrap">
                    <MultiSelectDropdown
                      name="filter_residency"
                      type={FieldType.MultiSelect}
                      options={ResidencyOptions}
                      label="Residency"
                      isClearable={true}
                      defaultValue={filters.filter_residency}
                      className="bg-white"
                      containerClassName="w-1/3 max-w-[32.33%]"
                      hideSearch
                    />
                    <MultiSelectDropdown
                      name="filter_occupation"
                      type={FieldType.MultiSelect}
                      options={OccupationOptions}
                      label="Occupation"
                      isClearable={true}
                      defaultValue={filters.filter_occupation}
                      className="bg-white"
                      containerClassName="w-1/3 max-w-[32.33%]"
                      inputContainerClassName="w-[90%]"
                    />
                    <Input
                      name="filter_home_region"
                      label="Home region"
                      type={FieldType.Input}
                      placeholder="Search home region"
                      containerClassName="w-1/3 max-w-[32.33%]"
                      className="!w-full"
                    />
                  </div>
                  <div className="flex items-center space-x-3 justify-between mt-5">
                    <Button
                      variant="primary"
                      type="submit"
                      form="filter"
                      className="space-x-2 flex justify-center items-center !px-11"
                    >
                      <Icon name="search" className="text-gray-900" />
                      <span>Search</span>
                    </Button>
                    <Button
                      variant="gray"
                      className="flex items-center"
                      onClick={() => {
                        setValue("search", "");
                        setValue("filter_home_region", "");
                        reset();
                        setFilters({ ...defaultFilter });
                        queryClient.invalidateQueries([
                          `/api/user/admin/staff`,
                        ]);
                        setCurrentPage(1);
                      }}
                    >
                      <Icon name="close" />
                      <span className="ml-2">Clear all filters</span>
                    </Button>
                  </div>
                </div>
              )}
              {getActiveFilterValues()}
            </form>
          </FormProvider>
          <div className="w-full mt-1">
            {staff && staff.length > 0
              ? staff.map((person) => (
                  <StaffDetail staff={person} key={person.id} />
                ))
              : getNoResult()}
          </div>
          <FormProvider
            {...{
              ...formMethods,
              handleSubmit,
              setValue,
              getValues,
              reset,
              watch,
            }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
            />
          </FormProvider>
          <div className="mt-3 py-3 border-t border-gray-200">
            <Button
              variant="gray"
              onClick={() => navigate(`/dashboard/staff`)}
              className="flex justify-center items-center py-3 pl-3"
              size="noSize"
            >
              <Icon name="back" />
              <span className="ml-2 text-gray-500 text-sm mr-3 font-semibold">
                Go back to Staff dashboard
              </span>
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}
