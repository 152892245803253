import { User } from "api/types";
import {
  ExpiringDocument,
  fetchStaffExpiringDocuments,
  useGetStaffExpiringDocuments,
} from "api/user";
import Badge, { BadgeProps } from "components/Badge";
import Button from "components/Button";
import { Icon } from "components/Icon";
import Notification from "components/Notification";
import Pagination from "components/Pagination";
import SortableTable, { Column, SortOptions } from "components/SortableTable";
import {
  checkExpiry,
  ExpiryStatus,
  getAvatarName,
  getContentByExpiry,
} from "components/StaffDetail";
import { FormData } from "components/types";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { downloadFile } from "utils/file";
import {
  capitalizeFirstLetter,
  cleanObject,
  formatDate,
  formatDateWithDay,
  serializeParams,
} from "utils/util";
import { DashboardStaffOutletContext } from "./staff";

export const getPropsForExpiry = (
  expiryStatus: ExpiryStatus,
  status: string
): BadgeProps => {
  if (expiryStatus === ExpiryStatus.EXPIRED) {
    return {
      message: status,
      className: `bg-red-100 !text-red-800 text-xs leading-4 font-medium !inline-flex !rounded-full px-2`,
      isIconOnRight: true,
      iconClassName: "text-red-700",
    };
  }
  if (expiryStatus === ExpiryStatus.ABOUT_TO_EXPIRE_IN_2_MONTH) {
    return {
      message: status,
      className:
        "bg-yellow-100 text-yellow-800 text-xs leading-4 font-medium !inline-flex !rounded-full px-2",
      isIconOnRight: true,
      iconClassName: "!text-yellow-700",
    };
  }
  if (expiryStatus === ExpiryStatus.ABOUT_TO_EXPIRE_IN_1_MONTH) {
    return {
      message: status,
      className:
        "bg-orange-100 text-orange-700 text-xs leading-4 font-medium !inline-flex !rounded-full px-2",
      statusClassName: "!m-0 !mr-1",
      isIconOnRight: true,
    };
  }
  return {
    message: status,
    className:
      "text-xs leading-4 font-medium tracking-wider !text-gray-700 !px-0 !rounded-none",
    statusClassName: "!m-0",
  };
};

export const handleViewProfile = (userId: number) => {
  const url = `/staff/view-profile/${userId}`;
  window.open(url, "_blank")?.focus();
};

const columns: Column<ExpiringDocument>[] = [
  {
    header: "NAME",
    accessor: "user",
    sortable: true,
    Cell: (value: string | number | User, row: ExpiringDocument) =>
      row.user ? (
        <div
          className="flex items-center justify-start space-x-4 cursor-pointer w-full pr-1"
          onClick={() => handleViewProfile(row.user.id)}
        >
          <Avatar
            name={getAvatarName(value as User)}
            size="32"
            round
            textSizeRatio={2.28}
            className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
            color="#D1F5FC"
            fgColor="#194860"
            src={row.user.avatar}
          />
          <span className="underline text-sm leading-5 font-semibold underline text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis w-full">{`${row.user.firstName} ${row.user.lastName}`}</span>
        </div>
      ) : (
        <></>
      ),
    columnWidth: "25%",
  },
  {
    header: "OCCUPATION",
    accessor: "user",
    Cell: (value: string | number | User, row: ExpiringDocument) => (
      <span className="text-sm leading-5 font-normal text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis w-full">
        {row.user.staffProfile.occupation}
      </span>
    ),
    columnWidth: "25%",
  },
  {
    header: "DOCUMENTS",
    accessor: "type",
    sortable: true,
    Cell: (value: string | number | User, row: ExpiringDocument) => (
      <div
        className="text-gray-500 inline-flex"
        data-tooltip-id={row.id?.toString()}
        data-tooltip-content={getContentByExpiry(checkExpiry(row.expiringAt))}
      >
        <Badge
          {...getPropsForExpiry(
            checkExpiry(row.expiringAt),
            capitalizeFirstLetter(row.type?.toLowerCase())
          )}
        />
        <Tooltip id={row.id?.toString()} />
      </div>
    ),
    columnWidth: "15%",
  },
  {
    header: "EXPIRING DATE",
    accessor: "expiringAt",
    sortable: true,
    Cell: (value: string | number | User, row: ExpiringDocument) => (
      <span className="text-sm leading-5 font-medium text-gray-900">
        {formatDate(new Date(value as string))}
      </span>
    ),
    columnWidth: "15%",
  },
  {
    header: "PHONE NUMBER",
    accessor: "user",
    Cell: (value: string | number | User, row: ExpiringDocument) => (
      <span className="text-sm leading-5 font-normal text-gray-700">
        {row.user.mobile}
      </span>
    ),
    columnWidth: "15%",
  },
  {
    header: "",
    accessor: "document",
    Cell: (value: string | number | User, row: ExpiringDocument) => (
      <span
        className="text-sm leading-5 font-medium text-primary-600 cursor-pointer"
        onClick={() => downloadFile(value as string)}
      >
        View
      </span>
    ),
    columnWidth: "5%",
  },
];

export interface ExpiringDocumentsFilters {
  [k: string]: string;
}

const fieldToEndpointMapping = {
  type: "document",
  expiringAt: "expiring_at",
  user: "name",
};

export default function ExpiringDocuments() {
  const { isAdmin } = useOutletContext<DashboardStaffOutletContext>();
  const componentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { handleSubmit, setValue, getValues, watch, reset, ...formMethods } =
    useForm<FormData>();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const defaultFilter: Partial<ExpiringDocumentsFilters> = {
    take: "10",
    page: "1",
  };
  const [filters, setFilters] = useState({ ...defaultFilter });
  const [isError, setIsError] = useState(false);
  const [sortConfig, setSortConfig] =
    useState<SortOptions<ExpiringDocument> | null>(null);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilters({ ...filters, page: page.toString() });
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setFilters({ ...filters, page: (currentPage + 1).toString() });
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setFilters({ ...filters, page: (currentPage - 1).toString() });
  };
  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page whenever items per page change
    setFilters({ ...filters, page: "1", take: newItemsPerPage.toString() });
  };

  const [documents, setDocuments] = useState<ExpiringDocument[]>([]);
  const { data, isFetching } = useGetStaffExpiringDocuments(
    isAdmin,
    cleanObject({
      ...filters,
    })
  );

  useEffect(() => {
    if (data) {
      if (Array.isArray(data.documents) && data.documents) {
        setDocuments(data.documents);
        setTotalItems(data.count);
        return;
      }
      setIsError(true);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (sortConfig) {
      setFilters({
        page: currentPage.toString(),
        take: itemsPerPage.toString(),
        orderBy:
          fieldToEndpointMapping[
            sortConfig.sortField as keyof typeof fieldToEndpointMapping
          ],
        orderType: sortConfig.sortOrder,
      });
    }
  }, [sortConfig, currentPage, itemsPerPage]);

  const exportPDF = async () => {
    const doc = new jsPDF();
    const queryParams = serializeParams({ report: true });
    const response = await fetchStaffExpiringDocuments(queryParams);

    // Add a heading
    doc.setFontSize(16); // Set font size for the heading
    doc.text("Expiring documents", doc.internal.pageSize.width / 2, 10, {
      align: "center",
    });

    const tableColumn = columns
      .filter((col) => col.accessor !== "document")
      .map((col) => col.header);
    const tableRows: any[] = [];

    (response?.documents || []).forEach((item) => {
      //const rowData = columns.filter((col) => col.accessor !== 'document').map(col => item[col.accessor]);
      const rowData = [
        `${item.user.title} ${item.user.firstName} ${item.user.lastName}`,
        item.user.staffProfile.occupation,
        item.type,
        formatDate(new Date(item.expiringAt)),
        item.user.mobile,
      ];
      tableRows.push(rowData);
    });

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      columnStyles: {
        0: { cellWidth: 45 },
        1: { cellWidth: 50 },
        2: { cellWidth: 40 },
        3: { cellWidth: 35 },
        4: { cellWidth: 30 },
      },
      margin: { left: 5, top: 5 },
      styles: { halign: "center" },
      tableWidth: "wrap",
      startY: 15, // Start the table below the heading
    });

    doc.save(
      `expiring_documents_${formatDateWithDay(new Date()).replaceAll(" ", "_")}.pdf`
    );
  };

  if (isError) {
    return (
      <Notification
        type="error"
        message={"Error in fetching data from server"}
      />
    );
  }

  return (
    <div className="py-1">
      <header className="mx-auto max-w-7xl">
        <div className="font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-300 pt-10 pb-3 flex justify-between">
          <h1 className="text-3xl flex-1">Expiring documents</h1>
          <div className="flex items-center justify-center space-x-2">
            <Button variant="primary" size="base" onClick={() => exportPDF()}>
              Export to PDF
            </Button>
          </div>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl pt-8">
          <div ref={componentRef}>
            <SortableTable
              columns={columns}
              data={[...documents]}
              setSortConfig={setSortConfig}
              sortConfig={sortConfig}
              containerClassName="!shadow-none rounded-t-md border-b-0"
            />
          </div>
          <div className="border border-gray-200 py-3 px-6 rounded-b-md">
            <FormProvider
              {...{
                ...formMethods,
                handleSubmit,
                setValue,
                getValues,
                reset,
                watch,
              }}
            >
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                onNextPage={handleNextPage}
                onPreviousPage={handlePreviousPage}
                containerClassName="!mt-0"
              />
            </FormProvider>
          </div>
          <div className="mt-3 py-3 border-t border-gray-200">
            <Button
              variant="gray"
              onClick={() => navigate(`/dashboard/staff`)}
              className="flex justify-center items-center py-3 pl-3"
              size="noSize"
            >
              <Icon name="back" />
              <span className="ml-2 text-gray-500 text-sm mr-3 font-semibold">
                Go back to Staff dashboard
              </span>
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}
