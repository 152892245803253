import classNames from "classnames";
import React from "react";
import DataTable, { CellData } from "./DataTable";

interface DataTableTableProps {
  title: string;
  heading: React.ReactNode;
  data: CellData[][];
  footer?: React.ReactNode;
  footerClassName?: string;
  shouldDisplay?: boolean;
  parentContainerClassName?: string;
  bodyContainerClassName?: string;
  headingContainerClassName?: string;
  isDisabled?: boolean;
  titleClassName?: string;
  additionalTitleText?: React.ReactNode;
  additionalInformationContainer?: React.ReactNode;
  sectionId?: string;
}

const DataTableContainer: React.FC<DataTableTableProps> = ({
  title,
  heading,
  data,
  footer,
  footerClassName,
  shouldDisplay = true,
  parentContainerClassName,
  bodyContainerClassName,
  headingContainerClassName,
  isDisabled = false,
  titleClassName,
  additionalTitleText,
  additionalInformationContainer,
  sectionId,
}) => {
  if (!shouldDisplay) {
    return <></>;
  }
  return (
    <div
      className={classNames(
        "bg-white shadow rounded-md  mt-8",
        parentContainerClassName
      )}
      id={sectionId}
    >
      <div className={classNames("px-3 py-5", bodyContainerClassName)}>
        <div
          className={classNames(
            "text-lg leading-6 font-semibold text-gray-900 px-3",
            isDisabled && "!text-gray-400",
            additionalTitleText && "flex items-center justify-between",
            titleClassName
          )}
        >
          <h3>{title}</h3>
          {additionalTitleText}
        </div>
        {heading && (
          <div className={classNames("my-3 px-3", headingContainerClassName)}>
            {heading}
          </div>
        )}
        <DataTable data={data} isDisabled={isDisabled} />
      </div>
      {footer && (
        <div
          className={classNames(
            "bg-gray-50 rounded-b-md px-5 py-2.5 border-t border-gray-200 w-full flex",
            footerClassName
          )}
        >
          {footer}
        </div>
      )}
      {additionalInformationContainer}
    </div>
  );
};

export default DataTableContainer;
