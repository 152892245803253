import {
  DOCUMENTS_WITH_EXPIRY_TYPE,
  DocumentToBEType,
  DocumentToStepNumberEnum,
  ExpiredDocuments,
} from "api/types";
import { updateExpiredDocument } from "api/user";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { validateDate } from "routes/onboarding/staff/steps/step1";
import {
  formatDate,
  getDateAfterPeriod,
  getTodayDate,
  removeObjectFields,
} from "utils/util";
import { UserContextData, useUser } from "../context/UserContext"; // Assuming you store user data in context
import Button from "./Button";
import ReusableForm from "./Form";
import { Icon } from "./Icon";
import Modal from "./Modal";
import Notification from "./Notification";
import NotificationBar from "./NotificationBar";
import { FieldType, FormData } from "./types";
import { handleGenericResponse, invalidateQueries } from "./utility";

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

const getFields = (documentType: keyof typeof DOCUMENTS_WITH_EXPIRY_TYPE) => {
  switch (documentType) {
    case DOCUMENTS_WITH_EXPIRY_TYPE.Visa:
      return [
        {
          name: "visaType",
          label: "Visa type *",
          type: FieldType.Select,
          placeholder: "Select",
          options: [
            { label: "Studying and training", value: "Studying and training" },
            {
              label: "Work and skilled",
              value: "Work and skilled",
            },
            { label: "Visitor", value: "Visitor" },
            { label: "Family and partner", value: "Family and partner" },
            { label: "Permanent resident", value: "Permanent resident" },
            {
              label: "Refugee and humanitarian",
              value: "Refugee and humanitarian",
            },
            { label: "Other", value: "Other" },
          ],
          parentFormContainerClassName: "w-1/3  inline-block align-top md:pr-6",
          className: "w-full",
          containerClassName: "w-full",
          requiredCondition: "This field is required",
        },
        {
          name: "visaClass",
          label: "Visa Class/Subclass i.e TU/573 *",
          type: FieldType.Input,
          className: "!w-full pr-3",
          placeholder: "Visa Class example",
          parentFormContainerClassName: "w-1/3  inline-block align-top ",
          requiredCondition: "This field is required",
        },
        {
          name: "workEntitlement",
          label: "Work entitlements *",
          type: FieldType.Radio,
          className: "w-96",
          parentFormContainerClassName: "w-1/3  inline-block align-top md:pl-6",
          requiredCondition: "This field is required",
          options: [
            { label: "Limited", value: "limited" },
            {
              label: "Unlimited",
              value: "unlimited",
            },
          ],
        },
        {
          name: "visaGrantDate",
          label: "Visa grant date *",
          type: FieldType.Calendar,
          parentFormContainerClassName: "w-1/3  inline-block align-top md:pr-6",
          requiredCondition: "This field is required",
          maxDate: getTodayDate(),
          className: "!w-full",
          wrapperClassName: "w-full",
        },
        {
          name: "visaExpiryDate",
          label: "Visa expiry date (Visa must be valid for at least 6 months)*",
          type: FieldType.Calendar,
          parentFormContainerClassName: "w-1/3  inline-block align-top",
          requiredCondition: "This field is required",
          validateFunction: validateDate,
          className: "!w-full pr-3",
          minDate: getDateAfterPeriod(0, 6),
          wrapperClassName: "w-full",
        },
        {
          name: "vevoRecord",
          label: "Upload vevo *",
          type: FieldType.FileUpload,
          parentFormContainerClassName: "w-90",
          requiredCondition: "This field is required",
          externalLink:
            "https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online/visa-holders",
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE["Police Check"]:
      return [
        {
          name: "policeCheck",
          label: "Police check *",
          buttonLabel: "Upload police check",
          type: FieldType.FileUpload,
          parentFormContainerClassName:
            "w-2/3 inline-block align-top pr-6 mb-8",
          key: "policeCheck",
          isMultiple: false,
          requiredCondition: "This field is required",
        },
        {
          name: "policeCheckValidUntil",
          label: "Police check expiry date *",
          type: FieldType.Calendar,
          parentFormContainerClassName:
            "w-full md:w-1/3 inline-block align-top",
          wrapperClassName: "w-full",
          minDate: getTodayDate(),
          className: "w-full",
          requiredCondition: "This field is required",
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE.Passport:
      return [
        {
          name: "passportNumber",
          label: "Passport number",
          requiredCondition: "This field is required",
          type: FieldType.Input,
          parentFormContainerClassName: "w-1/2  inline-block align-top md:pr-6",
          placeholder: "Input passport number",
          className: "mt-1",
        },
        {
          name: "passportExpiryDate",
          label: "Passport expiry date",
          requiredCondition: "This field is required",
          type: FieldType.Calendar,
          parentFormContainerClassName: `w-1/2  inline-block align-top `,
          className: "w-full",
          wrapperClassName: "w-full",
          minDate: getTodayDate(),
          placeholder: "Input passport expiry date",
        },
        {
          name: "passport",
          label: "Certified copy of Passport",
          requiredCondition: "This field is required",
          type: FieldType.FileUpload,
          parentFormContainerClassName:
            "w-full flex items-center justify-center",
          fileUploadContainerClassName: "md:w-full my-3",
          externalLink: "https://www.justice.vic.gov.au/certifiedcopies",
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE["Vehicle Registration"]:
      return [
        {
          name: "vehicleRegistrationExpiry",
          label: "Registration expiry date *",
          type: FieldType.Calendar,
          parentFormContainerClassName: "w-2/5 inline-block align-top md:pr-6",
          wrapperClassName: "w-full",
          className: "!w-full",
          minDate: getTodayDate(),
          requiredCondition: "This field is required",
        },
        {
          name: "vehicleRegistration",
          label: "Vehicle registration *",
          type: FieldType.FileUpload,
          isMultipleFiles: false,
          buttonLabel: "Upload vehicleRegistration",
          parentFormContainerClassName:
            "w-3/5 inline-block align-top pr-2  mb-8",
          requiredCondition: "This field is required",
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE["Vehicle Insurance"]:
      return [
        {
          name: "vehicleInsuranceExpiry",
          label: "Vehicle insurance expiry date *",
          type: FieldType.Calendar,
          parentFormContainerClassName: "w-2/5 inline-block align-top md:pr-6",
          wrapperClassName: "w-full",
          className: "!w-full",
          minDate: getTodayDate(),
          requiredCondition: "This field is required",
        },
        {
          name: "vehicleInsurance",
          label: "Vehicle insurance *",
          type: FieldType.FileUpload,
          isMultipleFiles: false,
          buttonLabel: "Upload Vehicle insurance *",
          parentFormContainerClassName:
            "w-3/5 inline-block align-top pr-2  mb-8",
          requiredCondition: "This field is required",
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE["Driving License"]:
      return [
        {
          name: "drivingLicenseNumber",
          label: "Driving licence number *",
          type: FieldType.Input,
          parentFormContainerClassName: "w-1/2 inline-block align-top pr-6",
          className: "!w-full mt-1",
          requiredCondition: "This field is required",
        },
        {
          name: "drivingLicenseExpiry",
          label: "Driving licence expiry date *",
          type: FieldType.Calendar,
          parentFormContainerClassName: "w-1/2 inline-block align-top",
          wrapperClassName: "w-full",
          className: "!w-full",
          minDate: getTodayDate(),
          requiredCondition: "This field is required",
        },
        {
          name: "drivingLicenseFront",
          label: "Certified driving licence (Front) *",
          type: FieldType.FileUpload,
          isMultipleFiles: false,
          buttonLabel: "Upload Certified driving licence (Front) *",
          parentFormContainerClassName: "w-1/2 inline-block align-top pr-6",
          requiredCondition: "This field is required",
        },
        {
          name: "drivingLicenseBack",
          label: "Certified driving licence (Back) *",
          type: FieldType.FileUpload,
          isMultipleFiles: false,
          buttonLabel: "Upload Certified driving licence (Back) *",
          parentFormContainerClassName: "w-1/2 inline-block align-top",
          requiredCondition: "This field is required",
        },
      ];
    default:
      return [];
  }
};

export const sortDocumentsByType = (
  data: ExpiredDocuments[]
): ExpiredDocuments[] => {
  return data.sort((a, b) => {
    const typeA =
      DocumentToStepNumberEnum[
        a.documentType as keyof typeof DocumentToStepNumberEnum
      ];
    const typeB =
      DocumentToStepNumberEnum[
        b.documentType as keyof typeof DocumentToStepNumberEnum
      ];
    return typeA - typeB;
  });
};

const ExpiredDocument: React.FC = () => {
  const { user, loading, expiredDocument } = useUser() as UserContextData;
  const [isOpen, setIsOpen] = useState(false);
  const [stepNumber, setStepNumber] = useState(1);
  const [sortedDocuments, setSortedDocuments] = useState<ExpiredDocuments[]>(
    []
  );
  const formMethods = useForm<FormData>({ shouldUnregister: true });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (expiredDocument) {
      setSortedDocuments(
        sortDocumentsByType(
          expiredDocument.filter((dt) => dt.status !== "submitted")
        )
      );
      if (!window.localStorage.getItem("modalTabClosed")) {
        window.localStorage.setItem("modalTabClosed", "false");
      }
      setIsOpen(true);
    }
  }, [expiredDocument]);

  const handleCloseModal = () => {
    window.localStorage.setItem("modalTabClosed", "true");
    setIsOpen(false);
  };

  if (loading || !user?.id || !sortedDocuments.length) {
    return null; // or a loading spinner
  }

  if (user?.staffProfile?.documentStatus === "ok") {
    return <></>;
  }

  const noOfRecords = sortedDocuments.map((_, index) => index + 1);
  const documentsRequired = sortedDocuments.map((dt) => dt.documentType);
  const currentDocument = sortedDocuments[stepNumber - 1];
  const expiredDocumentsConfig: {
    [a: string]: {
      notificationTitle: string;
      notificationBody: string;
      modalTitle: string;
    };
  } = {
    [DOCUMENTS_WITH_EXPIRY_TYPE.Visa]: {
      notificationTitle: "Your Visa has expired!",
      notificationBody:
        "Please submit your new Visa and VEVO record for review and approval. Access to your account will be restricted if the Visa is not updated within 28 days from " +
        formatDate(new Date(currentDocument.currentExpiryDate)),
      modalTitle: "New Visa details",
    },
    [DOCUMENTS_WITH_EXPIRY_TYPE["Police Check"]]: {
      notificationTitle: "Your Police check has expired!",
      notificationBody:
        "Please submit your new Police check for review and approval. Access to your account will be restricted if the Visa is not updated within 28 days from " +
        formatDate(new Date(currentDocument.currentExpiryDate)),
      modalTitle: "Police check details",
    },
    [DOCUMENTS_WITH_EXPIRY_TYPE.Passport]: {
      notificationTitle: "Your Passport has expired!",
      notificationBody:
        "Please submit your new Passport for review and approval.",
      modalTitle: "New Passport details",
    },
    [DOCUMENTS_WITH_EXPIRY_TYPE["Vehicle Registration"]]: {
      notificationTitle: "Your Vehicle registration has expired!",
      notificationBody:
        "Please submit your new Vehicle registration for review and approval.",
      modalTitle: "New Vehicle registration details",
    },
    [DOCUMENTS_WITH_EXPIRY_TYPE["Vehicle Insurance"]]: {
      notificationTitle: "Your vehicle insurance has expired!",
      notificationBody:
        "Please submit your new vehicle insurance for review and approval.",
      modalTitle: "New vehicle insurance details",
    },
    [DOCUMENTS_WITH_EXPIRY_TYPE["Driving License"]]: {
      notificationTitle: "Your driving licence has expired!",
      notificationBody:
        "Please submit your new driving licence for review and approval.",
      modalTitle: "New driving licence details",
    },
  };

  const onSubmit = async (data: FormData) => {
    data = removeObjectFields(data, FIELDS_TO_REMOVE);
    const documentBEType = DocumentToBEType[currentDocument.documentType];
    const response = await updateExpiredDocument(
      { [documentBEType]: data },
      currentDocument.id
    );

    const result = await handleGenericResponse(response);
    result && invalidateQueries(queryClient, [`/api/user/expired-documents`]);
    return;
  };

  return (
    <div>
      <NotificationBar
        message="You have an expired document(s) to update!"
        iconName="exclamation"
        messageClassName="!text-yellow-900"
        iconClassName="!text-yellow-500"
        onActionClick={() => {
          window.localStorage.setItem("modalTabClosed", "false");
          setIsOpen(true);
        }}
        actionText="Update"
      />
      {isOpen && (
        <Modal
          isOpen={
            isOpen && window.localStorage.getItem("modalTabClosed") === "false"
          }
          onClose={handleCloseModal}
          modalHeader={
            expiredDocumentsConfig[
              documentsRequired[
                stepNumber - 1
              ] as keyof typeof DOCUMENTS_WITH_EXPIRY_TYPE
            ]?.modalTitle ||
            "Expect to see some or all of the following forms for you to complete:"
          }
          modalBodyClassName="!m-0"
          modalHeaderClassName="!px-6 !py-5 border-b border-gray-200"
          modalContentClassName="!p-0"
          modalHeaderTitleClassName="!m-0"
          customModalStyles={{
            maxWidth: "1216px",
            width: "1216px",
          }}
        >
          {
            <FormProvider {...formMethods}>
              <div className="p-6">
                <Notification
                  type="warningExclamation"
                  message={
                    expiredDocumentsConfig[
                      documentsRequired[
                        stepNumber - 1
                      ] as keyof typeof DOCUMENTS_WITH_EXPIRY_TYPE
                    ].notificationTitle
                  }
                  bodyText={
                    expiredDocumentsConfig[
                      documentsRequired[
                        stepNumber - 1
                      ] as keyof typeof DOCUMENTS_WITH_EXPIRY_TYPE
                    ].notificationBody
                  }
                  textClassName="!text-yellow-800"
                  iconClassName="!text-yellow-500"
                  messageClassName="!text-yellow-900"
                  className="!m-0"
                />
              </div>
              <ReusableForm
                fields={getFields(
                  documentsRequired[
                    stepNumber - 1
                  ] as keyof typeof DOCUMENTS_WITH_EXPIRY_TYPE
                )}
                onSubmit={onSubmit}
                id={`edit-${stepNumber}`}
                formClassName="p-6 pt-0"
                formMethods={{
                  ...formMethods,
                }}
              />
              <div className="bg-gray-50 flex justify-between space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap">
                <div className="flex justify-between items-center space-x-3">
                  <Button
                    variant="white"
                    onClick={() => setStepNumber(stepNumber - 1)}
                    disabled={stepNumber === 1}
                    className="space-x-2"
                  >
                    <Icon
                      name="downChevron"
                      className="text-gray-600 animate rotate-90"
                      size={16}
                    />
                    <span className="text-sm leading-5 font-semibold text-gray-800">
                      Previous
                    </span>
                  </Button>
                  <Button
                    variant="white"
                    onClick={() => setStepNumber(stepNumber + 1)}
                    disabled={stepNumber === noOfRecords.length}
                    className="space-x-2"
                  >
                    <span className="text-sm leading-5 font-semibold text-gray-800">
                      Next
                    </span>
                    <Icon
                      name="downChevron"
                      className="text-gray-600 animate -rotate-90"
                      size={16}
                    />
                  </Button>
                </div>
                <div className="flex flex-1 justify-center items-center space-x-2">
                  {noOfRecords.map((page, index) =>
                    typeof page === "number" ? (
                      <Button
                        key={page}
                        variant={stepNumber === page ? "text" : "gray"}
                        className={`!py-2 rounded-md font-semibold w-9 h-9 ${
                          stepNumber === page
                            ? "bg-primary-100 outline outline-offset-2 outline-primary-500"
                            : ""
                        }`}
                        onClick={() => setStepNumber(page)}
                      >
                        {page}
                      </Button>
                    ) : (
                      <Button
                        variant="icon"
                        size="noSize"
                        key={page}
                        className="px-4 py-2 text-gray-600  w-9 h-9"
                      >
                        {page}
                      </Button>
                    )
                  )}
                </div>
                <Button
                  variant="primary"
                  type="submit"
                  form={`edit-${stepNumber}`}
                >
                  Submit
                </Button>
              </div>
            </FormProvider>
          }
        </Modal>
      )}
    </div>
  );
};

export default ExpiredDocument;
