import classNames from "classnames";
import { FormField } from "components/types";
import { InputHTMLAttributes, useEffect } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { generatePassword, get } from "utils/util";
import Button from "./Button";
import { renderErrorMessage } from "./utility";

export interface InputProps
  extends FormField,
    Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "type" | "name" | "defaultValue"
    > {
  subType?: string;
  error?: FieldError | undefined;
  isFlex?: boolean;
  parentContainerClassName?: string;
  isGeneratePassword?: boolean;
  containerClassName?: string;
  id?: string;
  children?: React.ReactNode;
  validationFunction?: (value: string) => boolean;
  validationErrorMessage?: string;
  inputContainerClassName?: string;
  additionalTriggerRequired?: boolean;
}
export default function Input({
  label,
  subType = "text",
  placeholder,
  name,
  error,
  isFlex = false,
  requiredCondition,
  className,
  parentContainerClassName,
  isGeneratePassword = false,
  additionalValidationProps,
  containerClassName,
  children,
  defaultValue,
  isDisabled,
  validationFunction,
  validationErrorMessage,
  parentFormContainerClassName,
  onBlur,
  isHiglighted,
  inputContainerClassName,
  additionalTriggerRequired = false,
  ...props
}: InputProps) {
  const {
    register,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    control,
    trigger,
    watch,
  } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    if (additionalTriggerRequired) {
      if (requiredCondition) {
        trigger(name); // Validate again dynamically
      } else {
        clearErrors(name); // Clear errors if condition changes to false
        trigger(name);
      }
    }
  }, [
    requiredCondition,
    name,
    trigger,
    clearErrors,
    additionalTriggerRequired,
  ]);

  const handleGeneratePassword = (inputName: string) => {
    const randomPassword = generatePassword();
    setValue && setValue(name, randomPassword);
    trigger(name);
  };

  const stateValue = watch(name);

  return (
    <div
      className={classNames(
        isFlex ? "flex justify-between items-center" : "",
        "mt-5",
        containerClassName
      )}
    >
      {label && (
        <label
          className={classNames(
            "block text-sm font-semibold leading-6 text-gray-700",
            isDisabled && "!text-gray-400"
          )}
        >
          {label}
        </label>
      )}
      <div
        className={classNames(
          isGeneratePassword ? "" : parentContainerClassName
        )}
      >
        <div
          className={classNames(
            isGeneratePassword && "flex items-center",
            inputContainerClassName
          )}
        >
          <Controller
            name={name}
            control={control}
            rules={{ required: requiredCondition }}
            defaultValue={defaultValue as string}
            render={({ field }) => {
              return (
                <>
                  <input
                    {...props}
                    id={name}
                    type={subType}
                    placeholder={placeholder}
                    {...register(name, {
                      required: requiredCondition,
                      validate: (value) =>
                        validationFunction && requiredCondition
                          ? validationFunction(value) || validationErrorMessage
                          : true,
                      ...additionalValidationProps,
                    })}
                    onChange={(e) => {
                      field.onChange(e);
                      if (requiredCondition && validationFunction) {
                        const isValid = validationFunction(e.target.value);
                        if (isValid) {
                          clearErrors(name);
                        } else {
                          setError(name, {
                            type: "manual",
                            message: validationErrorMessage,
                          });
                        }
                      }
                    }}
                    onBlur={(eve) => {
                      field.onBlur();
                      onBlur && onBlur(eve);
                    }}
                    autoComplete="off"
                    className={classNames(
                      "block w-full h-10 text-gray-900 rounded-md border-0 p-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 sm:text-sm sm:leading-6",
                      "focus:ring-2 focus:ring-inset focus:ring-primary-400 focus:shadow ",
                      {
                        "!ring-red-600": get(errors, name),
                        "!focus:ring-red-600": get(errors, name),
                      },
                      subType === "number" &&
                        "[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
                      "disabled:text-gray-400 disabled:border-gray-300 disabled:placeholder:text-gray-400",
                      isHiglighted &&
                        requiredCondition &&
                        !stateValue &&
                        "ring-secondary-500",
                      className
                    )}
                    defaultValue={defaultValue as string}
                    disabled={isDisabled}
                  />
                </>
              );
            }}
          />

          {isGeneratePassword && (
            <Button
              variant="tonal"
              label="Generate"
              onClick={() => handleGeneratePassword(name)}
              className="ml-3"
              type="button"
            />
          )}
          {children}
        </div>
        {renderErrorMessage(get(errors, name) as FieldError)}
      </div>
    </div>
  );
}
