import {
  DOCUMENTS_WITH_EXPIRY_TYPE,
  ExpiredDocuments,
  RejectionHistory,
  User,
} from "api/types";
import classNames from "classnames";
import React from "react";
import Avatar from "react-avatar";
import { handleViewProfile } from "routes/dashboard/ExpiringDocuments";
import { downloadFile } from "utils/file";
import {
  capitalizeFirstLetter,
  formatDate,
  formatDateWithTime,
  getFileNameFromS3Url,
} from "utils/util";
import Button from "./Button";
import SortableTable, { Column } from "./SortableTable";
import { getAvatarName } from "./StaffDetail";
import { Section } from "./UserDetailsContent";

interface ExpiredDocumentReviewDetailProps {
  document: ExpiredDocuments;
  documentType: string;
  containerClassName?: string;
}

const rejectionTableColumns: Column<RejectionHistory>[] = [
  {
    header: "NAME",
    accessor: "rejectedBy",
    sortable: true,
    Cell: (value: string | number | User, row: RejectionHistory) =>
      row.rejectedBy ? (
        <div
          className="flex items-center justify-start space-x-4 cursor-pointer w-full pr-1"
          onClick={() => handleViewProfile(row.rejectedBy?.id)}
        >
          <Avatar
            name={getAvatarName(row.rejectedBy as User)}
            size="32"
            round
            textSizeRatio={2.28}
            className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
            color="#D1F5FC"
            fgColor="#194860"
            src={row.rejectedBy.avatar}
          />
          <span className="underline text-sm leading-5 font-semibold underline text-gray-900 whitespace-nowrap overflow-hidden text-ellipsis w-full">{`${row.rejectedBy?.firstName} ${row.rejectedBy?.lastName}`}</span>
        </div>
      ) : (
        <></>
      ),
    columnWidth: "25%",
  },
  {
    header: "DATE AND TIME",
    accessor: "rejectedAt",
    sortable: true,
    Cell: (value: string | number | User, row: RejectionHistory) => (
      <span className="text-sm leading-5 font-medium text-gray-900">
        {row.rejectedAt ? formatDateWithTime(new Date(row.rejectedAt)) : ""}
      </span>
    ),
    columnWidth: "25%",
  },
  {
    header: "REASON FOR REJECTION",
    accessor: "comment",
    Cell: (value: string | number | User, row: RejectionHistory) => (
      <span className="text-sm leading-5 font-normal text-gray-700">
        {row.comment}
      </span>
    ),
    columnWidth: "50%",
  },
];

const getFields = (
  documentType: string,
  document: ExpiredDocuments
): Section[] => {
  if (!document.details) {
    return [];
  }
  switch (documentType) {
    case DOCUMENTS_WITH_EXPIRY_TYPE.Visa:
      return [
        {
          title: "New Visa details",
          details: [
            {
              label: "Visa type",
              value: capitalizeFirstLetter(document.details.visaType),
            },
            {
              label: "Visa Class/Subclass",
              value: capitalizeFirstLetter(document.details.visaClass),
            },
            {
              label: "Work entitlements",
              value: capitalizeFirstLetter(document.details.workEntitlement),
            },
            {
              label: "Visa grant date",
              value: formatDate(new Date(document.details.visaGrantDate)),
            },
            {
              label: "Visa expiry date",
              value: formatDate(new Date(document.details.visaExpiryDate)),
            },
            {
              label: "Upload VEVO record",
              value: (
                <Button
                  variant="icon"
                  onClick={() => downloadFile(document.details.vevoRecord)}
                  className={classNames(
                    "text-primary-600 underline text-sm font-medium text-start"
                  )}
                  type="button"
                  size="noSize"
                >
                  {getFileNameFromS3Url(document.details.vevoRecord)}
                </Button>
              ),
            },
          ],
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE.Passport:
      return [
        {
          title: "Passport",
          details: [
            {
              label: "Passport number",
              value: document.details.passportNumber,
            },
            {
              label: "Passport expiry data",
              value: formatDate(new Date(document.details.passportExpiryDate)),
            },
            {
              label: "Certified copy of Passport",
              value: (
                <Button
                  variant="icon"
                  onClick={() => downloadFile(document.details.passport)}
                  className={classNames(
                    "text-primary-600 underline text-sm font-medium text-start"
                  )}
                  type="button"
                  size="noSize"
                >
                  {getFileNameFromS3Url(document.details.passport)}
                </Button>
              ),
            },
          ],
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE["Police Check"]:
      return [
        {
          title: "Police check",
          details: [
            {
              label: "Police check",
              value: (
                <Button
                  variant="icon"
                  onClick={() => downloadFile(document.details.policeCheck)}
                  className={classNames(
                    "text-primary-600 underline text-sm font-medium text-start"
                  )}
                  type="button"
                  size="noSize"
                >
                  {getFileNameFromS3Url(document.details.policeCheck)}
                </Button>
              ),
            },
            {
              label: "Police check expiry date",
              value: formatDate(
                new Date(document.details.policeCheckValidUntil)
              ),
            },
          ],
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE["Vehicle Registration"]:
      return [
        {
          title: "Vehicle registration",
          details: [
            {
              label: "Registration expiry date",
              value: formatDate(
                new Date(document.details.vehicleRegistrationExpiry)
              ),
            },
            {
              label: "Vehicle registration",
              value: (
                <Button
                  variant="icon"
                  onClick={() =>
                    downloadFile(document.details.vehicleRegistration)
                  }
                  className={classNames(
                    "text-primary-600 underline text-sm font-medium text-start"
                  )}
                  type="button"
                  size="noSize"
                >
                  {getFileNameFromS3Url(document.details.vehicleRegistration)}
                </Button>
              ),
            },
          ],
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE["Driving License"]:
      return [
        {
          title: "Driving licence",
          details: [
            {
              label: "Driving licence number",
              value: document.details.drivingLicenseNumber,
            },
            {
              label: "Driving licence expiry date",
              value: formatDate(
                new Date(document.details.drivingLicenseExpiry)
              ),
            },
            {
              label: "Certified driving licence Front",
              value: (
                <Button
                  variant="icon"
                  onClick={() =>
                    downloadFile(document.details.drivingLicenseFront)
                  }
                  className={classNames(
                    "text-primary-600 underline text-sm font-medium text-start"
                  )}
                  type="button"
                  size="noSize"
                >
                  {getFileNameFromS3Url(document.details.drivingLicenseFront)}
                </Button>
              ),
            },
            {
              label: "Certified driving licence Back",
              value: (
                <Button
                  variant="icon"
                  onClick={() =>
                    downloadFile(document.details.drivingLicenseBack)
                  }
                  className={classNames(
                    "text-primary-600 underline text-sm font-medium text-start"
                  )}
                  type="button"
                  size="noSize"
                >
                  {getFileNameFromS3Url(document.details.drivingLicenseBack)}
                </Button>
              ),
            },
          ],
        },
      ];
    case DOCUMENTS_WITH_EXPIRY_TYPE["Vehicle Insurance"]:
      return [
        {
          title: "Vehicle Insurance",
          details: [
            {
              label: "Vehicle insurance expiry date",
              value: formatDate(
                new Date(document.details.vehicleInsuranceExpiry)
              ),
            },
            {
              label: "Vehicle insurance",
              value: (
                <Button
                  variant="icon"
                  onClick={() =>
                    downloadFile(document.details.vehicleInsurance)
                  }
                  className={classNames(
                    "text-primary-600 underline text-sm font-medium text-start"
                  )}
                  type="button"
                  size="noSize"
                >
                  {getFileNameFromS3Url(document.details.vehicleInsurance)}
                </Button>
              ),
            },
          ],
        },
      ];
    default:
      return [{ title: "", details: [] }];
  }
};

const ExpiredDocumentReviewDetail: React.FC<
  ExpiredDocumentReviewDetailProps
> = ({ document, containerClassName, documentType }) => {
  const isRejected = document.rejectionHistory?.length > 0;
  return (
    <div>
      <div className={classNames("shadow rounded-lg", containerClassName)}>
        <h3 className="bg-primary-100 px-6 h-7.5 pt-1 text-sm leading-5 font-semibold text-primary-900 rounded-t-lg">
          Personal details
        </h3>
        <div className="px-6 py-2.5 bg-gray-50 border-b border-gray-200 rounded-b-lg">
          <div className="flex space-x-12 py-1 ">
            <div
              className="flex items-center justify-start cursor-pointer"
              onClick={() => handleViewProfile(document.staffProfile?.user?.id)}
            >
              <Avatar
                name={getAvatarName(document.staffProfile?.user)}
                size="32"
                round
                textSizeRatio={2.28}
                className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
                color="#D1F5FC"
                fgColor="#194860"
                src={document.staffProfile?.user?.avatar}
              />
              <div className="flex flex-col items-start ml-4 ">
                <span className="underline text-base leading-6 font-semibold text-gray-900">{`${document.staffProfile?.user?.firstName} ${document.staffProfile?.user?.lastName}`}</span>
              </div>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Occupation
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {document.staffProfile?.occupation || "N/A"}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Email
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {document.staffProfile?.user?.email || "N/A"}
              </span>
            </div>
            <div className="flex flex-col items-start">
              <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                Phone
              </span>
              <span className="text-sm leading-5 font-medium text-gray-900">
                {document.staffProfile?.user?.mobile || "N/A"}
              </span>
            </div>
          </div>
        </div>
      </div>
      {getFields(documentType, document).map((section, index) => (
        <div key={index} className={classNames("mt-6")}>
          {section.title && (
            <h3 className="bg-primary-100 px-6 h-7.5 pt-1 text-sm leading-5 font-semibold text-primary-900 rounded-t-lg shadow">
              {section.title}
            </h3>
          )}
          <div className="px-6 bg-white shadow rounded-b-lg">
            {section.details.map((detail, idx) => (
              <div
                key={idx}
                className={classNames(
                  "flex justify-between items-center py-3 ",
                  section.details.length - 1 !== idx &&
                    "border-b border-gray-200"
                )}
              >
                {detail.label && (
                  <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                    {detail.label}
                  </span>
                )}
                <span className="text-sm leading-5 font-normal text-gray-700">
                  {detail.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
      {isRejected && (
        <div key={"Rejection Reasons"} className={classNames("mt-6")}>
          <h3 className="bg-primary-100 px-6 h-7.5 pt-1 text-sm leading-5 font-semibold text-primary-900 rounded-t-lg shadow">
            Rejection Reasons
          </h3>
          <div className="bg-white shadow rounded-b-lg">
            <div className="mx-auto">
              <SortableTable
                columns={rejectionTableColumns}
                data={document.rejectionHistory}
                setSortConfig={() => {}}
                sortConfig={null}
                containerClassName="!shadow-none rounded-t-md border-b-0"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpiredDocumentReviewDetail;
