import { TwoFASetup } from "api/types";
import classNames from "classnames";
import Button from "components/Button";
import ForgotPassword from "components/ForgotPassword";
import ForgotUsername from "components/ForgotUsername";
import { useUser } from "context/UserContext";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { user } from "../api";
import Input from "../components/Input";

export default function Login() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openForgotUsernameDialogue, setOpenForgotUsernameDialogue] =
    useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [sixDigitCode, setSixDigitCode] = useState("");
  const [show2FA, setShow2FA] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [error, setError] = useState<{
    username: string | null;
    password: string | null;
    email: string | null;
    sixDigitCode: string | null;
  }>({
    username: null,
    password: null,
    email: null,
    sixDigitCode: null,
  });
  const { refreshUser } = useUser();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!username || username.trim() === "") {
      setError({ ...error, username: "Username is required" });
      return;
    }
    if (!password || password.trim() === "") {
      setError({ ...error, password: "Password is required" });
      return;
    }
    try {
      const response = await user.login(username, password);
      console.log("Login successful", response);
      setLoginError(null);
      window.localStorage.setItem("eicare_auth", JSON.stringify(response));
      const res = await user.getUser();
      if (
        res &&
        (!res.twoFARequired || res.twoFASetup !== TwoFASetup.SETUP_COMPLETE)
      ) {
        const redirectTo = searchParams.get("redirectTo") || "/dashboard";
        navigate(redirectTo);
        await refreshUser();
        return;
      }
      setShow2FA(true);
    } catch (error) {
      console.error("Login failed", error);
      setLoginError("Login failed. Please try again.");
    }
  };

  const handle2FALogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!sixDigitCode || sixDigitCode.trim() === "") {
      setError({ ...error, sixDigitCode: "Code is required" });
      return;
    }
    try {
      await user.TwoFALogin(sixDigitCode);
      const redirectTo = searchParams.get("redirectTo") || "/dashboard";
      navigate(redirectTo);
      await refreshUser();
    } catch (err) {
      console.error("Login failed", err);
      setLoginError("Login failed. Please try again.");
      setError({ ...error, sixDigitCode: "Code is invalid" });
    }
  };

  return (
    <>
      <div className="flex w-full min-h-screen">
        <div className="relative hidden w-2/3 flex-2 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/2c13b5620398dc9b37e5fa8e7e83a65a.jpeg"
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center px-24 pb-12 w-1/3 lg:w-full bg-primary-50">
          <div className="mx-auto w-full max-w-sm lg:w-96 h-full flex flex-col">
            <div className="flex flex-1 flex-col justify-center">
              <div>
                <img
                  className="min-h-44 w-full lg:h-80 lg:w-auto"
                  src="/images/ei-care-logo.png"
                  alt="EICARE"
                />
                <h2 className="mt-6 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  {!show2FA ? `Welcome to EICARE!` : "Authenticate Your Login"}
                </h2>
              </div>
              <div className="mt-8">
                {!show2FA ? (
                  <form onSubmit={onSubmit} method="POST">
                    <div className="">
                      <Input
                        label="Username"
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => {
                          setUsername(e.target.value);
                          setError({ ...error, username: null });
                        }}
                        error={error.username}
                      />
                    </div>

                    <div className="mt-4">
                      <Input
                        label="Password"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setError({ ...error, password: null });
                        }}
                        error={error.password}
                      />
                    </div>

                    <div className="mt-8">
                      <Button
                        type="submit"
                        variant="primary"
                        size="xl"
                        className="w-full"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          className="mr-2 mt-0.5 h-full"
                        >
                          <path
                            d="M9.16667 13.3333L5.83333 10M5.83333 10L9.16667 6.66668M5.83333 10L17.5 10M13.3333 13.3333V14.1667C13.3333 15.5474 12.214 16.6667 10.8333 16.6667H5C3.61929 16.6667 2.5 15.5474 2.5 14.1667V5.83334C2.5 4.45263 3.61929 3.33334 5 3.33334H10.8333C12.214 3.33334 13.3333 4.45263 13.3333 5.83334V6.66668"
                            stroke="#111827"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-base leading-6 font-bold text-gray-900">
                          Login
                        </span>
                      </Button>
                    </div>
                    <div className="flex items-center mt-3">
                      <div className="text-sm leading-6">
                        <span
                          className="font-semibold text-primary-700 hover:text-primary-600 cursor-pointer"
                          onClick={() => setOpenDialogue(true)}
                        >
                          Forgot password?
                        </span>
                        <span
                          className="ml-3 font-semibold text-primary-700 hover:text-primary-600 cursor-pointer"
                          onClick={() => setOpenForgotUsernameDialogue(true)}
                        >
                          Forgot username?
                        </span>
                        <ForgotPassword
                          openDialogue={openDialogue}
                          setOpenDialogue={setOpenDialogue}
                        />
                        <ForgotUsername
                          openDialogue={openForgotUsernameDialogue}
                          setOpenDialogue={setOpenForgotUsernameDialogue}
                        />
                      </div>
                    </div>
                    <p
                      className={classNames(
                        "text-red-600 text-base mt-6",
                        loginError ? "visible" : "invisible"
                      )}
                    >
                      {loginError || "Login failed"}
                    </p>
                  </form>
                ) : (
                  <form onSubmit={handle2FALogin} method="POST">
                    <p className="text-sm leading-5 font-semibold text-gray-900">
                      To continue, please enter the 6-digit code generated on
                      your authenticator app.
                    </p>

                    <div className="mt-4">
                      <Input
                        label=""
                        type="text"
                        placeholder="Enter 6-digit authentication code"
                        value={sixDigitCode}
                        onChange={(e) => {
                          setSixDigitCode(e.target.value);
                          setError({ ...error, sixDigitCode: null });
                        }}
                        error={error.sixDigitCode}
                      />
                    </div>

                    <div className="mt-4">
                      <Button
                        type="submit"
                        variant="primary"
                        size="xl"
                        className="w-full"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          className="mr-2 mt-0.5 h-full"
                        >
                          <path
                            d="M9.16667 13.3333L5.83333 10M5.83333 10L9.16667 6.66668M5.83333 10L17.5 10M13.3333 13.3333V14.1667C13.3333 15.5474 12.214 16.6667 10.8333 16.6667H5C3.61929 16.6667 2.5 15.5474 2.5 14.1667V5.83334C2.5 4.45263 3.61929 3.33334 5 3.33334H10.8333C12.214 3.33334 13.3333 4.45263 13.3333 5.83334V6.66668"
                            stroke="#111827"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-base leading-6 font-bold text-gray-900">
                          Login
                        </span>
                      </Button>
                    </div>
                    <div className="flex items-center mt-3">
                      <div className="text-sm leading-6">
                        <span
                          className="font-semibold text-primary-700 hover:text-primary-600 cursor-pointer"
                          onClick={() => setOpenDialogue(true)}
                        >
                          Forgot password?
                        </span>
                        <span
                          className="ml-3 font-semibold text-primary-700 hover:text-primary-600 cursor-pointer"
                          onClick={() => setOpenForgotUsernameDialogue(true)}
                        >
                          Forgot username?
                        </span>
                        <ForgotPassword
                          openDialogue={openDialogue}
                          setOpenDialogue={setOpenDialogue}
                        />
                        <ForgotUsername
                          openDialogue={openForgotUsernameDialogue}
                          setOpenDialogue={setOpenForgotUsernameDialogue}
                        />
                      </div>
                    </div>
                    <p
                      className={classNames(
                        "text-red-600 text-base mt-6",
                        loginError ? "visible" : "invisible"
                      )}
                    >
                      {loginError || "Login failed"}
                    </p>
                  </form>
                )}
              </div>
            </div>
            <div className="text-gray-700 text-xs">
              ©2024 EICARE, All rights reserved | 2 Rose Street Clayton VIC,
              3168.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
