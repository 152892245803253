import classNames from "classnames";
import React from "react";

type InputProps = {
  label: string;
  type: "text" | "password";
  placeholder: string;
  value: string;
  defaultValue?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | null;
  labelClassName?: string;
  disabled?: boolean;
};
export default function Input({
  label,
  type,
  placeholder,
  value,
  onChange,
  error,
  labelClassName,
  disabled,
  defaultValue,
  ...props
}: InputProps) {
  return (
    <div {...props}>
      <label
        htmlFor="email"
        className={classNames(
          "block text-sm font-medium leading-6 text-gray-700",
          disabled && "!text-gray-400",
          labelClassName
        )}
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          className={classNames(
            "block w-full h-10 text-gray-900 rounded-md border-0 p-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 sm:text-sm sm:leading-6",
            "focus:ring-2 focus:ring-inset focus:ring-primary-400 focus:shadow ",
            {
              "ring-red-600": !!error,
              "focus:ring-red-600": !!error,
            },
            "disabled:text-gray-400 disabled:border-gray-300 disabled:placeholder:text-gray-400"
          )}
        />
        {error && (
          <p className="mt-1 text-sm text-red-700" id="email-error">
            {error}
          </p>
        )}
      </div>
    </div>
  );
}
