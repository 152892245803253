import { Comment } from "components/Comments";
import { CheckListItems } from "routes/ViewStaffDetails/viewStaffDetailUtility";

export interface ResponseData {
  [key: string]: any;
}
export interface GenericResponse {
  status?: number | string;
  statusCode?: number | string;
  data: ResponseData & ErrorResponse;
  statusText?: string;
  message?: string;
  isError?: boolean;
}

interface ErrorResponse {
  validation?: {
    body: {
      keys: string[];
      message: string;
    };
  };
}

export enum UserStatus {
  "Completed" = "Completed",
  "Cannot_Complete" = "Cannot_Complete",
  "In_Progress" = "In_Progress",
  "In_Review" = "In_Review",
  "Invited" = "Invited",
}

export enum FailureReasons {
  "Failed_Interview" = "Failed_Interview",
  "Failed_Statutory" = "Failed_Statutory",
  "Failed_Reference" = "Failed_Reference",
  "Failed_Police_Check" = "Failed_Police_Check",
  "Failed_Health_Check" = "Failed_Health_Check",
  "No_Work_Rights" = "No_Work_Rights",
  "Invite_Declined" = "Invite_Declined",
  "Invite_Expired" = "Invite_Expired",
  "Job_Application_Terminated" = "Job_Application_Terminated",
}

export interface UserRole {
  id: number;
  userId: number;
  roleId: number;
  user: User;
  role: Role;
  createdAt: string;
  updatedAt: string;
}

export interface Role {
  id: number;
  name: string;
  userRoles: UserRole[];
  createdAt: string;
  updatedAt: string;
}

export enum TwoFASetup {
  "NOT_SETUP" = "not_setup",
  "SETTING_UP" = "setting_up",
  "SETUP_COMPLETE" = "setup_complete",
}

export interface User {
  id: number;
  username: string;
  email: string;
  password: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  avatar: string;
  status: UserStatus;
  inviteCode: string;
  inviteCodeExpiry: string;
  inviteCodeGeneratedAt: string;
  resetPasswordToken: string;
  resetPasswordExpiry: string;
  resetPasswordTokenGeneratedAt: string;
  createdAt: string;
  updatedAt: string;
  userRoles: UserRole[];
  sessions: Session[];
  createdById: number;
  createdBy: User;
  createdUsers: User[];
  reviewedStaffs: StaffProfile[];
  interviewedStaffs: StaffProfile[];
  notification: Notification[];
  reportingStaffs: StaffProfile[];
  staffProfile: StaffProfile;
  roles: string[];
  homePhone: string;
  relationShip: string;
  homeTelephoneNo: string;
  occupation: string;
  failureReasons: FailureReasons[];
  reviewOutcome: boolean;
  archivedAt: string;

  prepared: boolean;
  goodTime: boolean;
  permissionToProceed: boolean;
  refCheckedAt: string;

  currentStaffStatus: "employed" | "unemployed";

  preferences: {
    expiredDocumentPopupWarning: boolean;
    firstLoginPopup: boolean;
  };

  createdAccountStatus: number;
  createdAccount: boolean;

  createdStaffStatus: number;

  twoFAStatus: boolean;
  twoFARequired: boolean;
  twoFAValid: boolean;
  twoFASetup: TwoFASetup;
}

export interface Staff {
  id: number;
  username: string;
  email: string;
  password: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile: string;
  avatar: string;
  status: UserStatus;
  inviteCode: string;
  inviteCodeExpiry: string;
  inviteCodeGeneratedAt: string;
  resetPasswordToken: string;
  resetPasswordExpiry: string;
  resetPasswordTokenGeneratedAt: string;
  createdAt: string;
  updatedAt: string;
  userRoles: UserRole[];
  sessions: Session[];
  createdById: number;
  createdBy: User;
  createdUsers: User;
  reviewedStaffs: StaffProfile;
  interviewedStaffs: StaffProfile;
  notification: Notification[];
  reportingStaffs: StaffProfile[];
  staffProfile: StaffProfile;
  failureReasons: FailureReasons[];
  archivedAt: string;
  currentStaffStatus: "employed" | "unemployed";
  createdAccountStatus: number;
  createdAccount: boolean;
}

export interface Session {
  id: number;
  token: string;
  userId: number;
  user: User;
  ip: string;
  userAgent: string;
  expires: string;
  valid: boolean;
  invalidatedAt: string;
  lastUsedAt: string;
  createdAt: string;
}

export interface StaffProfile {
  id: number;
  user: User;
  userId: number;
  occupation: string;
  onboardingStatus: number;
  createdAt: string;
  updatedAt: string;
  residencyStatus: string;
  legallyWorkInAustralia: boolean;
  visaType: string;
  visaClass: string;
  workEntitlement: string;
  visaGrantDate: string;
  visaExpiryDate: string;
  vevoRecord: string;
  homePhone: string;
  residentialAddress: string;
  mailingAddress: string;
  gender: string;
  dob: string;
  birthCountry: string;
  birthRegion: string;
  spokenLanguages: string[];
  passport: string;
  aboriginalTSIStatus: string;
  healthTermsUnderstood: boolean;
  termsPreExisting: boolean;
  termsFailureToDisclose: boolean;
  termsDeclarationPreExisting: boolean;
  termsDeclarationAcknowledgment: boolean;
  termsDeclaredStatement: string;
  healthDeclarationSignature: string;
  signedAt: string;
  covidVaccineCertificate: string;
  immunisationHistory: string;
  highestQualification: string;
  highestQualificationSchool: string;
  workCertificates: string[];
  foodHandlingCertificate: string;
  infectionControlCertificate: string;
  ndisWorkerOrientationCertificate: string;
  resume: string;
  reference1: User;
  reference2: User;
  policeCheck: string;
  policeCheckValidUntil: string;
  interviewDate: string;
  interviewTime: string;
  bankDetails: BankDetails;
  vehicleDetails: VehicleDetails;
  vehicleOwnership: boolean;
  reportingManagerId: number;
  employmentStartDate: string;
  employmentEndDate: string;
  interviewDetails: string;
  interviewOutcome: boolean;
  interviewConductedById: number;
  interviewConductedBy: User;
  loginDetailsSent: boolean;
  loginDetailsSentAt: string;
  termsDeclaredStatementDetails: string;
  status: string;
  profileReviewedById: number;
  profileReviewedBy: User;
  homeRegion: string;
  emergencyContact: User;

  signature: string;

  downloadEmployeeHandbook: boolean;
  acknowledgeCodeOfConduct: boolean;
  readEmployeeHandbook: boolean;
  signatureCodeOfConduct: string;
  signatureCodeOfConductDate: string;
  signedStatutoryForm: string;
  contractSigned: string;
  contractReceivedDate: string;

  australianBirthCertificate: string;
  citizenShipCertificate: string;
  passportExpiryDate: string;
  passportNumber: string;

  statutoryReviewOutcome: boolean;
  policeCheckResult: boolean;

  staffComment: Comment[];
  profileReviewChecklist: CheckListItems;

  interviewConfirmed: boolean;
  interviewers: number[];
  availiableToCommenceDate: string;

  referenceCheckAdmins1: number[];
  referenceCheckAdmins2: number[];

  vehicleReviewAdmins: number[];
  vehicleReviewSignedAt: string;
  vehicleReviewSignature: string;
  hazardAlert: string;

  contractAcknowledgedSignature: string;
  contractAcknowledgedAt: string;
  contractAcknowledged: boolean;
  isPersonalCareWorker: boolean;

  contractAdminSignature: string;
  contractAdminSignedAt: string;
  contractReportsToManager: User;
  reportingManager: User;
  contractReportsToManagerId: number;

  ahpra: string;
  ahpraDate: string;

  residentialAddressBuildingNo: string;
  residentialAddressStreetName: string;
  residentialAddressSuburb: string;
  residentialAddressState: string;
  residentialAddressZip: string;
  mailingAddressBuildingNo: string;
  mailingAddressStreetName: string;
  mailingAddressSuburb: string;
  mailingAddressState: string;
  mailingAddressZip: string;

  orientationItems: CheckListItems;

  orientationCheckListHistory: OrientationCheckListHistory[];

  documentStatus: DocumentStatus;
}

export type DocumentStatus =
  | "ok"
  | "expired"
  | "critical"
  | "submitted"
  | "rejected";

export interface OrientationCheckListHistory {
  name: string;
  checked: boolean;
  updatedAt: string;
  updatedBy: User;
}

interface BankDetails {
  paymentAuthorisation: boolean;
  bankName: string;
  branchAddress: string;
  accountName: string;
  accountType: string;
  accountNumber: string;
  branchNumber: string;
  tfn: string;
  superannuationFundChoice: string;
  superannuationFund: string;
}
interface VehicleDetails {
  ownVehicle: string;
  ownerName: string;
  vehicleMake: string;
  vehicleModel: string;
  engineSize: string;
  vehiclePlateNumber: string;
  vehicleRegistration: string;
  vehicleRegistrationExpiry: string;
  vehicleInsurance: string;
  vehicleInsuranceExpiry: string;
  drivingLicenseNumber: string;
  drivingLicense: string;
  drivingLicenseFront: string;
  drivingLicenseBack: string;
  drivingLicenseExpiry: string;
  vehicleOwner: string;
}

export interface InviteStaffRequest {
  email: string;
  occupation: string;
  role: string;
}

export const ADMIN_ROLES = ["super_admin", "admin"];
export const SUPER_ADMIN_ROLES = ["super_admin"];

export enum Outcome {
  PASSED = "Passed",
  FAILED = "Failed",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export interface ChecklistHistoryReviewItems {
  reviewedBy: string;
  reviewDateTime: string;
  reviewedItem: string;
}

export interface ChecklistHistory {
  items: ChecklistHistoryReviewItems[];
}

export interface UserDataChangeLog
  extends Partial<{
    id: string;
    staffProfileId: string;
    staffProfile: StaffProfile;
    changedById: string;
    changedBy: User;
    fieldName: string;
    value: string;
    ip: string;
    userAgent: string;
    createdAt: string;
    action: string;
    eventType: string;
    label: string;
  }> {}

export enum GENERICS {
  Yes = "Yes",
  No = "No",
}

export interface OccupationData {
  occupations: {
    [key: string]: {
      name: string;
      description: string;
    };
  };
  occupations_orientation: {
    [key: string]: string[];
  };
  orientation: {
    [key: string]: {
      name: string;
      description: string;
    };
  };
}

export interface TransformedOrientationData {
  description: string;
  occupations_orientation: {
    name: string;
    description: string;
  }[];
}

export const DOCUMENTS_WITH_EXPIRY_TYPE = {
  Visa: "Visa",
  "Police Check": "Police Check",
  Passport: "Passport",
  "Driving License": "Driving License",
  "Vehicle Insurance": "Vehicle Insurance",
  "Vehicle Registration": "Vehicle Registration",
};
export const DocumentToBEType = {
  Visa: "visa",
  "Police Check": "policeCheck",
  Passport: "passport",
  "Driving License": "drivingLicense",
  "Vehicle Insurance": "vehicleInsurance",
  "Vehicle Registration": "vehicleRegistration",
};

export enum DocumentToStepNumberEnum {
  Visa = 1,
  "Police Check",
  Passport,
  "Vehicle Registration",
  "Vehicle Insurance",
  "Driving License",
}

export const DOCUMENTS_WITH_EXPIRY = {
  vevoRecord: {
    name: "Visa",
    critical: true,
    type: DOCUMENTS_WITH_EXPIRY_TYPE.Visa,
  },
  passport: {
    name: "Passport",
    critical: false,
    type: DOCUMENTS_WITH_EXPIRY_TYPE.Passport,
  },
  policeCheck: {
    name: "Police Check",
    critical: true,
    type: DOCUMENTS_WITH_EXPIRY_TYPE["Police Check"],
  },
  vehicleRegistration: {
    name: "Vehicle Registration",
    critical: false,
    type: DOCUMENTS_WITH_EXPIRY_TYPE["Vehicle Registration"],
  },
  vehicleInsurance: {
    name: "Vehicle Insurance",
    critical: false,
    type: DOCUMENTS_WITH_EXPIRY_TYPE["Vehicle Insurance"],
  },
  drivingLicense: {
    name: "Driving License",
    critical: false,
    type: DOCUMENTS_WITH_EXPIRY_TYPE["Driving License"],
  },
};

export interface RejectionHistory {
  comment: string;
  rejectedAt: string;
  rejectedById: number;
  rejectedBy: User;
}

export interface ExpiredDocuments {
  id: number;
  documentType: keyof typeof DOCUMENTS_WITH_EXPIRY_TYPE;
  status: DocumentStatus;
  critical: boolean;
  comment: string;
  details: any;
  rejectionReason: string;
  staffProfileId: number;
  approvedById: number;
  rejectedById: number;
  rejectedBy: User;
  createdAt: string;
  updatedAt: string;
  rejectedAt: string;
  expiryDate: string;
  deletedAt: string;
  staffProfile: StaffProfile;
  expiringAt: string;
  submittedAt: string;
  currentExpiryDate: string;
  rejectionHistory: RejectionHistory[];
}

export enum NewEmployedStaffDocuments {
  Residency = 1,
  "PersonalDetails",
  HealthDeclaration,
  "TrainingAndQualification",
  "PoliceCheck",
  "BankDetails",
  "VehicleDetails",
  "EmployeeHandBook",
  "Contract",
}
