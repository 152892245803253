import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Icon, IconName } from "./Icon";

interface MenuItem {
  label: string;
  iconName: string;
  targetId?: string;
}

interface StickySidebarProps {
  items: MenuItem[];
  title?: string;
  className?: string;
}

const StickySidebar: React.FC<StickySidebarProps> = ({
  items,
  title,
  className = "",
}) => {
  const [activeItem, setActiveItem] = useState<string | null>(
    items?.[0]?.targetId || null
  );

  useEffect(() => {
    const handleScroll = () => {
      const sections = items.map((item) =>
        document.getElementById(item.targetId || "")
      );

      // Find the first visible section based on scroll position
      const visibleSection = sections.find((section) => {
        if (section) {
          const rect = section.getBoundingClientRect();
          return rect.top >= 0 && rect.top <= window.innerHeight / 8;
        }
        return false;
      });

      // If a visible section is found, update the active item
      if (visibleSection) {
        setActiveItem(visibleSection.id);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll, true);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [items]);

  const handleClick = (targetId?: string) => {
    if (targetId) {
      const section = document.getElementById(targetId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
        setActiveItem(targetId);
      }
    }
  };

  return (
    <div
      className={classNames(
        "sticky top-0 h-[calc(100vh-100px)] left-0 overflow-y-auto",
        className
      )}
    >
      {title && (
        <h3 className="text-purple-500 text-xl font-semibold mb-6">{title}</h3>
      )}
      <div className="space-y-3">
        {items.map((item, index) => (
          <div
            key={index}
            className={classNames(
              "flex items-center p-2 rounded-md cursor-pointer text-sm leading-6 font-medium group transition-all h-9",
              activeItem === item.targetId
                ? "bg-primary-100 text-gray-900 hover:bg-primary-200"
                : "text-gray-600 hover:text-gray-900 hover:bg-gray-200"
            )}
            onClick={() => handleClick(item.targetId)}
          >
            <Icon
              name={item.iconName as IconName}
              className={classNames(
                activeItem === item.targetId
                  ? "text-gray-900"
                  : "text-gray-600 group-hover:text-gray-900 hover:text-gray-900",
                "h-5 w-5 shrink-0 transition-all"
              )}
            />
            <span
              className={classNames(
                "ml-3 text-sm leading-5 font-medium group-hover:text-gray-900 text-gray-600 transition-all",
                activeItem === item.targetId && "text-gray-900 font-medium"
              )}
            >
              {item.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StickySidebar;
