import { TwoFASetup } from "api/types";
import { UserContextData, useUser } from "context/UserContext";
import { useEffect, useState } from "react";
import { user as userAPI } from "../api";
import Dialogue from "./Dialogue";
import { Icon } from "./Icon";
import Setup2FA from "./Setup2FA";

export default function Enable2FA() {
  const { user, loading, refreshUser } = useUser() as UserContextData;
  const [openDialogue, setOpenDialogue] = useState(false);
  const handleEnable2FA = async () => {
    await userAPI.TwoFASetup();
    setOpenDialogue(false);
    refreshUser();
  };

  useEffect(() => {
    user && setOpenDialogue(user.twoFASetup === TwoFASetup.NOT_SETUP);
  }, [user]);

  if (
    loading ||
    !user?.id ||
    (user && user.twoFASetup === TwoFASetup.SETUP_COMPLETE) ||
    !user.twoFARequired
  ) {
    return null; // or a loading spinner
  }

  if (user.twoFASetup === TwoFASetup.SETTING_UP) {
    return <Setup2FA />;
  }

  return (
    <Dialogue
      key={"enable2FA"}
      title="Let's start by securing your EICARE staff account"
      confirmButtonLabel="Enable Google Authenticator App"
      panelClassName="!w-[40rem]"
      containerClassName="!pb-2"
      confirmButtonClassName="!w-full !ml-0"
      titleClassName="text-lg"
      children={
        <div className="text-sm leading-5 font-normal text-gray-600">
          <p className="my-5">
            To protect your EICARE staff account, we need to add an extra layer
            of security by setting up a{" "}
            <span className="font-semibold">Google Authenticator app.</span>
          </p>
          <p>
            Once this has been set up, to log in to your account, you will enter
            your username and password as normal and complete an extra step by
            entering a 6-digit code/number generated from your Google
            Authenticator app.
          </p>
        </div>
      }
      open={openDialogue}
      handleConfirm={handleEnable2FA}
      iconElement={
        <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-12 sm:w-12 my-4">
          <Icon name="lock" className="h-6 w-6" />
        </div>
      }
    />
  );
}
