import {
  completeStaffProfileOnboarding,
  updateChecklistItems,
} from "api/staff";
import {
  ChecklistHistoryReviewItems,
  OrientationCheckListHistory,
  StaffProfile,
  TransformedOrientationData,
  User,
  UserDataChangeLog,
  UserStatus,
} from "api/types";
import Badge from "components/Badge";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import DataTableContainer from "components/DataTableContainer";
import { Icon } from "components/Icon";
import Modal from "components/Modal";
import { Option } from "components/Select";
import Table, { Column } from "components/Table";
import { FieldType } from "components/types";
import { handleGenericResponse } from "components/utility";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { parseUserAgent } from "../../utils/parseUserAgent";
import {
  getChangedFields,
  getFormattedDate,
  mergeObjects,
} from "../../utils/util";
import { StepNames } from "./staffDetail";
import { useStaffDetails } from "./useStaffDetails";

const isDisabledItem = [
  "bankingAndSuperannuationAddedToXero",
  "employmentStartDateAdded",
];

export const staffDetailChecklistItems = [
  {
    label: "Interview has been conducted",
    value: "interviewConducted",
  },
  {
    label: "Vehicle has been inspected",
    value: "vehicleInspected",
  },
  {
    label: "Contract has been signed by new staff",
    value: "contractSigned",
  },
  {
    label: "Banking and Superannuation emailed to Accounts",
    value: "bankingAndSuperannuationAddedToXero",
  },
  {
    label: "Staff account has been created in Sandwai",
    value: "accountCreatedInSandwai",
  },
  {
    label: "Staff has been added WeChat group for rostering",
    value: "addedToWechatRoster",
  },
  {
    label: "Employment start date added",
    value: "employmentStartDateAdded",
  },
  {
    label: "Reports to Manager/Supervisor selected",
    value: "reportingManagerSelected",
  },
  {
    label: "Staff profile has been reviewed and is complete",
    value: "staffProfileReviewedAndCompleted",
  },
];

export const checklistHistoryReviewItems: ChecklistHistoryReviewItems[] = [
  {
    reviewedBy: "John Doe",
    reviewedItem: "Vehicle has been inspected",
    reviewDateTime: "2024-06-15",
  },
  {
    reviewedBy: "Jane Smith",
    reviewedItem: "Interview has been conducted",
    reviewDateTime: "2024-09-22",
  },
  {
    reviewedBy: "Mike Johnson",
    reviewedItem: "Contract has been signed by new staff",
    reviewDateTime: "2024-12-03",
  },
];

export const columnsAccountHistory: Column<UserDataChangeLog>[] = [
  {
    header: "Date And Time",
    accessor: "createdAt",
    Cell: (value: string | User | StaffProfile | undefined) =>
      value ? (
        <span>{getFormattedDate(new Date(value as string))}</span>
      ) : (
        <></>
      ),
  },
  {
    header: "Who",
    accessor: "changedBy",
    Cell: (
      value: string | User | StaffProfile | undefined,
      row: UserDataChangeLog
    ) => (row.changedBy ? <span>{row.changedBy.username}</span> : <></>),
  },
  {
    header: "Email",
    accessor: "changedBy",
    Cell: (
      value: string | User | StaffProfile | undefined,
      row: UserDataChangeLog
    ) => (row.changedBy ? <span>{row.changedBy.email}</span> : <></>),
  },
  { header: "Event", accessor: "eventType" },
  { header: "Change log", accessor: "action" },
  {
    header: "Device",
    accessor: "userAgent",
    Cell: (
      value: string | User | StaffProfile | undefined,
      row: UserDataChangeLog
    ) =>
      row.userAgent ? (
        <span>{parseUserAgent(row.userAgent)?.device || "N/A"}</span>
      ) : (
        <></>
      ),
  },
  {
    header: "Operation system",
    accessor: "userAgent",
    Cell: (
      value: string | User | StaffProfile | undefined,
      row: UserDataChangeLog
    ) =>
      row.userAgent ? (
        <span>{parseUserAgent(row.userAgent)?.operatingSystem || "N/A"}</span>
      ) : (
        <></>
      ),
  },
  {
    header: "Source IP",
    accessor: "ip",
  },
  {
    header: "Browser",
    accessor: "userAgent",
    Cell: (
      value: string | User | StaffProfile | undefined,
      row: UserDataChangeLog
    ) =>
      row.userAgent ? (
        <span>{parseUserAgent(row.userAgent)?.browser || "N/A"}</span>
      ) : (
        <></>
      ),
  },
];
export const columnsChecklistHistoryReviewItems: Column<UserDataChangeLog>[] = [
  {
    header: "Reviewed by",
    accessor: "changedBy",
    Cell: (
      value: string | User | StaffProfile | undefined,
      row: UserDataChangeLog
    ) => (row.changedBy ? <span>{row.changedBy.username}</span> : <></>),
  },
  {
    header: "Review date and time",
    accessor: "createdAt",
    Cell: (value: string | User | StaffProfile | undefined) =>
      value ? (
        <span>{getFormattedDate(new Date(value as string))}</span>
      ) : (
        <></>
      ),
  },
  {
    header: "Event",
    accessor: "value",
    Cell: (value: string | User | StaffProfile | undefined) =>
      value ? (
        <Badge
          message="Checked"
          className="bg-primary-100 text-primary-900 !m-0 inline-flex px-2"
        />
      ) : (
        <Badge
          message="Unchecked"
          className="bg-secondary-100 text-secondary-800 !m-0 inline-flex px-2"
        />
      ),
  },
  {
    header: "Reviewed Item",
    accessor: "label",
  },
];

export const getOrientationCheckListHistoryItems = (
  orientationData: TransformedOrientationData
): Column<OrientationCheckListHistory>[] => [
  {
    header: "Reviewed by",
    accessor: "updatedBy",
    Cell: (
      value: string | User | boolean | undefined,
      row: OrientationCheckListHistory
    ) =>
      row.updatedBy ? (
        <span className="text-sm leading-5 font-normal text-gray-900">{`${row.updatedBy.firstName} ${row.updatedBy.lastName}`}</span>
      ) : (
        <></>
      ),
  },
  {
    header: "Review date and time",
    accessor: "updatedAt",
    Cell: (value: string | User | boolean | undefined) =>
      value ? (
        <span className="text-sm leading-5 font-normal text-gray-700">
          {getFormattedDate(new Date(value as string))}
        </span>
      ) : (
        <></>
      ),
  },
  {
    header: "Event",
    accessor: "checked",
    Cell: (value: string | User | boolean | undefined) =>
      value ? (
        <Badge
          message="Checked"
          className="bg-primary-100 text-primary-900 !m-0 inline-flex px-2"
        />
      ) : (
        <Badge
          message="Unchecked"
          className="bg-secondary-100 text-secondary-800 !m-0 inline-flex px-2"
        />
      ),
  },
  {
    header: "Reviewed Item",
    accessor: "name",
    Cell: (
      value: string | User | boolean | undefined,
      row: OrientationCheckListHistory
    ) =>
      value ? (
        <span className="text-sm leading-5 font-normal text-gray-700">
          {
            orientationData.occupations_orientation.find(
              (obj) => obj.name === value
            )?.description
          }
        </span>
      ) : (
        <></>
      ),
  },
];

export interface CheckListItems {
  [k: string]: Partial<{
    admin_email: string;
    admin_id: number;
    date: string;
    value: boolean;
  }>;
}

export const useProfileOverviewContent = (
  user: User,
  onEditClick: (stepName: StepNames) => void,
  adminStaffOptions: Option[],
  admin: User,
  isSuperAdmin: boolean,
  handleClose: () => void,
  reviewData: UserDataChangeLog[]
) => {
  const navigate = useNavigate();
  const [isDetailsConfirmed, setIsDetailsConfirmed] = useState(false);
  const checkListItem = user.staffProfile.profileReviewChecklist || {};
  const [selectedChecklist, setSelectedChecklist] = useState<CheckListItems>({
    ...checkListItem,
  });
  const [editSelectedChecklist, setEditSelectedChecklist] =
    useState<CheckListItems>({ ...checkListItem });
  const [isOpen, setIsOpen] = useState(false);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelectedChecklist((prevSelectedItems) => ({
      ...prevSelectedItems,
      ...{ [value]: { value: e.target.checked, admin_id: admin.id } },
    }));
  };
  const handleEditCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setEditSelectedChecklist({
      ...editSelectedChecklist,
      ...{ [value]: { value: e.target.checked, admin_id: admin.id } },
    });
  };
  const setChecklistItems = async (isEditType = false) => {
    const updatedList = isEditType
      ? { ...editSelectedChecklist }
      : { ...selectedChecklist };
    const updatedField = getChangedFields(checkListItem, updatedList);
    const data = mergeObjects(
      Object.keys(updatedField).map((item) => ({
        [item]: updatedList[item]?.value,
      }))
    );

    const response = await updateChecklistItems({ ...data }, user.id);
    handleGenericResponse(response);
    setSelectedChecklist({ ...updatedList });
    setEditSelectedChecklist({ ...updatedList });
    handleClose();
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setEditSelectedChecklist({ ...user.staffProfile.profileReviewChecklist });
    setSelectedChecklist({ ...user.staffProfile.profileReviewChecklist });
  };

  const completeProfile = async () => {
    const response = await completeStaffProfileOnboarding(user.id);
    if (response.statusText && response.status !== 200) {
      toast.error("Error in saving", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        icon: <Icon name="error" />,
      });
      return;
    }

    toast.success("Staff profile completed successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: <Icon name="check" />,
    });
    handleClose();
  };

  const isAnythingSelected = Object.keys(
    user.staffProfile.profileReviewChecklist || {}
  ).some((val) => selectedChecklist[val]?.value);

  const isAnythingSelectedForSave = Object.keys(selectedChecklist || {}).some(
    (val) => selectedChecklist[val]?.value
  );

  const isEverythingChecked =
    user.staffProfile.profileReviewChecklist &&
    Object.keys(user.staffProfile.profileReviewChecklist || {}).length ===
      staffDetailChecklistItems.length &&
    Object.keys(user.staffProfile.profileReviewChecklist || {}).every(
      (val) => user.staffProfile.profileReviewChecklist?.[val]?.value
    );

  return (
    <div className="pb-1 mt-8">
      <h3 className="text-xl leading-7 font-semibold text-gray-900 mb-8">
        Profile Overview
      </h3>
      {useStaffDetails(
        user,
        true,
        onEditClick,
        adminStaffOptions,
        admin,
        isSuperAdmin
      ).map((detail) => (
        <DataTableContainer
          title={detail.title}
          heading={detail.heading || <></>}
          data={detail.data}
          key={detail.title}
          footer={detail.footer}
          footerClassName={detail.footerClassName}
          // parentContainerClassName={detail.parentContainerClassName}
          shouldDisplay={detail.shouldDisplay}
          bodyContainerClassName={detail.bodyContainerClassName}
          headingContainerClassName={detail.headingContainerClassName}
          isDisabled={detail.isDisabled}
          additionalTitleText={detail.additionalTitleText}
          additionalInformationContainer={detail.additionalInformationContainer}
        />
      ))}
      <div className="p-5  bg-white shadow rounded-md  mt-8">
        <h3 className="text-lg leading-6 font-semibold text-gray-900 border-b border-gray-300 pb-5">
          Profile review checklist
        </h3>
        <div className="pt-5 flex flex-col md:flex-row justify-start">
          <div className="w-full md:w-96 mr-4">
            <div className="shadow rounded-md border pt-4">
              <h4 className="text-base leading-6 font-semibold text-gray-900 px-4">
                Checklist
              </h4>
              {staffDetailChecklistItems.map((item) => (
                <Checkbox
                  type={FieldType.Checbox}
                  label={item.label}
                  name={item.value.replace(" ", "_")}
                  className="!mt-4 px-4"
                  key={item.label}
                  onChange={(e) => handleCheckboxChange(e, item.value)}
                  isDisabled={
                    checkListItem[item.value]?.value ||
                    isDisabledItem.indexOf(item.value) > -1
                  }
                  checked={
                    selectedChecklist[item.value]?.value ||
                    checkListItem[item.value]?.value
                  }
                />
              ))}
              {
                <div className="bg-gray-50 flex justify-end items-center  rounded-b-md px-6 py-2.5 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap space-x-4">
                  <Button
                    variant="white"
                    onClick={() => setIsOpen(true)}
                    disabled={
                      !isAnythingSelected ||
                      user.status === UserStatus.Completed
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => setChecklistItems()}
                    disabled={
                      !isAnythingSelectedForSave ||
                      user.status === UserStatus.Completed
                    }
                  >
                    Save
                  </Button>
                </div>
              }
              <Modal
                isOpen={isOpen}
                onClose={handleCloseModal}
                modalHeader="Edit checklist"
                className="setWidth"
              >
                <div className="ml-4 px-3">
                  {[...staffDetailChecklistItems].map((item) => (
                    <Checkbox
                      type={FieldType.Checbox}
                      label={item.label}
                      name={`edit-${item.value.replace(" ", "_")}`}
                      className="mt-4"
                      key={`edit-${item.label}`}
                      onChange={(e) => handleEditCheckboxChange(e, item.value)}
                      defaultValue={
                        editSelectedChecklist[item.value]?.value ||
                        checkListItem[item.value]?.value
                      }
                      isDisabled={
                        (editSelectedChecklist[item.value]?.value &&
                          editSelectedChecklist[item.value]?.admin_id !==
                            admin.id &&
                          !isSuperAdmin) ||
                        isDisabledItem.indexOf(item.value) > -1
                      }
                    />
                  ))}
                </div>
                <div className="bg-gray-50 flex justify-end items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap space-x-4">
                  <Button variant="white" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => setChecklistItems(true)}
                  >
                    Save
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
          <div className="w-full flex-1 h-104 overflow-auto">
            <h4 className="text-base leading-6 font-semibold mt-2">
              Checklist history
            </h4>
            {reviewData.length > 0 && (
              <div className="p-3 mx-auto bg-gray-100 rounded-lg mt-2">
                <Table<UserDataChangeLog>
                  data={reviewData}
                  columns={columnsChecklistHistoryReviewItems}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-footer bg-gray-50 flex justify-between items-center  rounded-b-md pt-3 border-t border-gray-200 w-full mt-10">
        <Button
          variant="gray"
          onClick={() => navigate(`/dashboard/staff`)}
          className="flex justify-center items-center py-3 pl-3"
          size="noSize"
        >
          <Icon name="back" />
          <span className="ml-2 text-gray-500 text-sm mr-3 font-semibold">
            Go back to Staff dashboard
          </span>
        </Button>
        <div className="flex items-center">
          <Checkbox
            name="confirmDetails"
            label="I confirm all details are correct"
            type={FieldType.Checbox}
            isDisabled={
              user.status === UserStatus.Completed || !isEverythingChecked
            }
            onChange={() => setIsDetailsConfirmed(!isDetailsConfirmed)}
            defaultValue={user.status === UserStatus.Completed}
          />
          <Button
            variant="primary"
            type="button"
            disabled={
              user.status === UserStatus.Completed || !isDetailsConfirmed
            }
            className="ml-3"
            onClick={() => completeProfile()}
          >
            Complete
          </Button>
        </div>
      </div>
    </div>
  );
};
