import { updateStaffDetails } from "api/staff";
import { User } from "api/types";
import Button from "components/Button";
import DataTableContainer from "components/DataTableContainer";
import ReusableForm from "components/Form";
import Modal from "components/Modal";
import StickySidebar from "components/StickySidebar";
import { FormData, FormField } from "components/types";
import { handleGenericResponse } from "components/utility";
import { UserContextData, useUser } from "context/UserContext";
import { useState } from "react";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import ApplicationMenu from "routes/dashboard/Menu";
import {
  editContactDetails,
  editEmergencyContactDetail,
  editTraining,
} from "./fields";
import { usePersonalDetails } from "./usePersonalDetails";

export enum MyAccountStepNames {
  "CONTACT_DETAILS" = "CONTACT_DETAILS",
  "EMERGENCY_CONTACT" = "EMERGENCY_CONTACT",
  "TRAINING" = "TRAINING",
}

const StickySidebarItems = [
  {
    label: "Personal information",
    iconName: "user",
    targetId: "PersonalInfo",
  },
  {
    label: "Contact details",
    iconName: "genericPhone",
    targetId: "ContactDetails",
  },
  {
    label: "Emergency contact detail",
    iconName: "support",
    targetId: "EmergencyContactDetail",
  },
  {
    label: "Essential documents",
    iconName: "document",
    targetId: "EssentialDocuments",
  },
  {
    label: "Training and qualifications",
    iconName: "presentationChartBar",
    targetId: "TrainingAndQualifications",
  },
  {
    label: "Code of conduct",
    iconName: "clipboardList",
    targetId: "CodeOfConduct",
  },
  { label: "Contract", iconName: "clipboardCheck", targetId: "Contract" },
  {
    label: "Change password",
    iconName: "lockClosed",
    targetId: "ChangePassword",
  },
];

export const FIELDS_TO_REMOVE = [
  "calendarMonth",
  "calendarYear",
  "confirmDetails",
  "legallyWorkInAustralia",
  "emailCheckForValidation",
  "sameAsResidentialAddress",
  "ownerName",
];

const getFields = (
  user: User,
  stepName: MyAccountStepNames,
  formMethods: UseFormReturn<FormData, any, undefined>,
  setResidentialState: (value: string | undefined) => void,
  residentialState: string | undefined
): FormField[] => {
  const { getValues, setValue, watch } = formMethods;
  const watchedFields = watch();
  switch (stepName) {
    case MyAccountStepNames.CONTACT_DETAILS:
      return editContactDetails(
        getValues,
        setValue,
        user,
        setResidentialState,
        residentialState,
        watchedFields
      );
    case MyAccountStepNames.EMERGENCY_CONTACT:
      return editEmergencyContactDetail(user?.staffProfile, watchedFields);
    case MyAccountStepNames.TRAINING:
      return editTraining(user?.staffProfile);
    default:
      return editEmergencyContactDetail(user?.staffProfile, watchedFields);
  }
};

export const useEditableForm = (
  user: User,
  stepName: MyAccountStepNames | undefined,
  defaultFormMethods: UseFormReturn<FormData, any, undefined>,
  handleCloseModal: () => void
) => {
  const [residentialState, setResidentialState] = useState<string | undefined>(
    user?.staffProfile?.residentialAddressState || ""
  );
  if (!stepName) {
    return <h3>Please select a step to edit</h3>;
  }

  const { ...formMethods } = defaultFormMethods;

  const onSubmit = async (data: FormData) => {
    const response = await updateStaffDetails(data, FIELDS_TO_REMOVE);
    const result = await handleGenericResponse(response);
    result && handleCloseModal();
  };

  return (
    <ReusableForm
      fields={getFields(
        user,
        stepName,
        formMethods,
        setResidentialState,
        residentialState
      )}
      onSubmit={onSubmit}
      id={`edit-${stepName}`}
      formClassName="p-6 pt-0"
      formMethods={{
        ...formMethods,
      }}
    />
  );
};

export default function MyAccount() {
  const { user, refreshUser } = useUser() as UserContextData;
  const [isOpen, setIsOpen] = useState(false);
  const [stepName, setStepName] = useState<MyAccountStepNames>();
  const formMethods = useForm<FormData>({ shouldUnregister: true });

  const handleCloseModal = () => {
    setIsOpen(false);
    formMethods.reset();
    refreshUser();
  };

  const onEditClick = (stepName: MyAccountStepNames) => {
    setStepName(stepName);
    setIsOpen(true);
  };
  return (
    <div>
      <ApplicationMenu />
      <header className="mx-auto max-w-7xl">
        <div className="font-bold leading-tight tracking-tight text-gray-900 border-b border-gray-200 pt-10 pb-3 flex justify-between">
          <h1 className="text-3xl">My Account</h1>
        </div>
      </header>
      <main className="">
        <div className="flex mx-auto max-w-7xl pt-8">
          <div className="md:w-1/4 pr-8 hidden sm:block ">
            <StickySidebar items={StickySidebarItems} />
          </div>
          <div className="flex flex-col md:w-3/4 pl-2">
            <div className="flex-1 pl-8 space-y-16">
              {usePersonalDetails(user, onEditClick).map((detail) => (
                <DataTableContainer
                  title={detail.title}
                  heading={detail.heading || <></>}
                  data={detail.data}
                  key={detail.title}
                  footer={detail.footer}
                  footerClassName={detail.footerClassName}
                  sectionId={detail.sectionId}
                  parentContainerClassName={detail.parentContainerClassName}
                  //shouldDisplay={detail.shouldDisplay}
                  // bodyContainerClassName={detail.bodyContainerClassName}
                  // headingContainerClassName={detail.headingContainerClassName}
                  // isDisabled={detail.isDisabled}
                  //additionalTitleText={detail.additionalTitleText}
                  /* additionalInformationContainer={
                    detail.additionalInformationContainer
                  } */
                />
              ))}
            </div>
          </div>
        </div>
        <FormProvider {...formMethods}>
          <Modal
            isOpen={isOpen}
            onClose={handleCloseModal}
            modalHeader={`Edit ${(stepName || "")?.split("_")?.join(" ").toLowerCase()}`}
            className="large"
            modalBodyClassName="!m-0"
            modalHeaderClassName="!px-6"
          >
            {useEditableForm(user, stepName, formMethods, handleCloseModal)}
            <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full mt-3 flex-wrap md:flex-nowrap">
              <Button variant="white" onClick={() => handleCloseModal()}>
                Cancel
              </Button>
              <Button variant="primary" type="submit" form={`edit-${stepName}`}>
                Save
              </Button>
            </div>
          </Modal>
        </FormProvider>
      </main>
    </div>
  );
}
