import { createdAccountDocumentUpdate } from "api/staff";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import EmailInput from "components/EmailInput";
import FileUpload from "components/FileUpload";
import Notification, { NotificationProps } from "components/Notification";
import Radio from "components/RadioBox";
import Select from "components/Select";
import Sign from "components/Sign";
import Textarea from "components/Textarea";
import Input from "components/UnControlledInput";
import { FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  validateAustralianPhoneNumber,
  validateAustralianTelephoneNumber,
} from "utils/util";
import { CurrentEmployeeProps } from "./CurrentEmployeeStep1";

const FIELDS_TO_REMOVE = [
  "occupation",
  "residentialAddress",
  "username",
  "sameAsResidentialAddress",
  "calendarMonth",
  "calendarYear",
];

export default function CurrentEmployeeStep3({
  user,
  refreshUser,
  isToggled,
}: CurrentEmployeeProps) {
  const {
    getValues,
    watch,
    formState,
    handleSubmit,
    setError,
    ...formMethods
  } = useForm<FormData>();
  const { staffProfile } = user;
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const termsDeclarationPreExisting = watch("termsDeclarationPreExisting");
  const methods = {
    ...formMethods,
    getValues,
    formState,
    watch,
    handleSubmit,
    setError,
  };

  const homePhone = watch("emergencyContact.homeTelephoneNo");

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    data.termsDeclarationPreExisting =
      data.termsDeclarationPreExisting === "yes" ? true : false;
    const response = await createdAccountDocumentUpdate(
      3,
      data,
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(
      response,
      setError,
      setResponse,
      "Submitted successfully"
    );
    isSuccess && (await refreshUser());
  };

  return (
    <div className="bg-white shadow rounded-md">
      <div className="flex flex-col ">
        {response ? (
          <Notification
            type={response.type}
            message={response.message}
            className="my-3 !inline-flex w-max"
          />
        ) : (
          <></>
        )}
      </div>
      <div className="px-6 pt-3">
        <h3 className="text-gray-900 text-lg font-semibold">
          1 - My Health: Pre-existing injury or health declaration
        </h3>
        <p className="text-gray-500 text-sm">
          In accordance with the Section 41 of Workplace Injury and
          Rehabilitation Act 2013, you are required to disclose all pre-existing
          injury or disease that you have suffered or which you could reasonably
          for foresee could be affected by the nature of the employment proposed
          in the position description provided.
        </p>
        <Notification
          type="exclamation"
          message="S41(2) of the Act will apply if you fail to disclose or make a false or misleading disclosure."
          className="!mx-0 my-5 !inline-flex"
          iconClassName="text-primary-500"
        />
        <h5 className="text-gray-900 text-base font-semibold">Extract</h5>
        <p className="text-gray-900 text-sm font-medium mb-3">
          Workplace injury and Rehabilitation Act 2013, Section 41:
        </p>
        <h6 className="text-gray-900 text-sm font-semibold">(1) Subsection</h6>
        <h6 className="text-gray-900 text-sm font-semibold">
          (2) Applies if it is proved that, before commencing employment with
          the employer
        </h6>
        <p className="ml-3 text-gray-900 text-sm font-medium">
          (a) A worker had a pre-existing injury or disease of which the worker
          was are; and
        </p>
        <p className="ml-3 text-gray-900 text-sm font-medium">
          (b) The employer in writing:
        </p>
        <p className="ml-6 text-gray-900 text-sm font-normal flex items-center my-5">
          <div className="rounded-full p-2 bg-gray-200 flex items-center justify-center w-6 h-6 text-xs font-semibold">
            I
          </div>
          <span className="ml-3">
            Advised the worker as to the nature of the proposed employment; and
          </span>
        </p>
        <p className="ml-6 text-gray-900 text-sm font-normal flex items-start mb-5">
          <div className="rounded-full p-2 bg-gray-200 flex items-center justify-center w-6 h-6 text-xs font-semibold">
            II
          </div>
          <span className="ml-3">
            Requested the worker to disclose all pre-existing injuries and
            diseases suffered by the worker of which the worker was aware and
            could reasonably be expected to foresee could be affected by the
            nature of the proposed employment; and
          </span>
        </p>
        <p className="ml-6 text-gray-900 text-sm font-normal flex items-center mb-5">
          <div className="rounded-full p-2 bg-gray-200 flex items-center justify-center w-6 h-6 text-xs font-semibold">
            III
          </div>
          <span className="ml-3">
            Advised the worker that subsection (2) will apply to a failure to
            make such a disclosure or the making of a false or misleading
            disclosure; and
          </span>
        </p>
        <p className="ml-6 text-gray-900 text-sm font-normal flex items-start">
          <div className="rounded-full p-2 bg-gray-200 flex items-center justify-center w-6 h-6 text-xs font-semibold">
            IV
          </div>
          <span className="ml-3">
            Advised the worker as to the effect of subsection (2) on the
            worker’s entitlement to compensation; and (2) if this subsection
            applies, any recurrence, aggravation, acceleration, exacerbation or
            deterioration of the pre-existing injury or disease arising out of
            or in the course of or due to the nature of employment with the
            employer does not entitle the worker to compensation under this act.
          </span>
        </p>
      </div>
      <FormProvider {...methods}>
        <form id={"currentStaffDocument-3"} onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6 pr-0 text-sm font-medium">
            <div className="flex items-center justify-start flex-wrap md:flex-nowrap">
              <span className="mr-3">I,</span>
              <Input
                name="username"
                type={FieldType.Input}
                label=""
                containerClassName="w-52 !mt-0"
                defaultValue={user.username}
                isDisabled={true}
              />
              <span className="mx-3">of Address</span>
              <Input
                name="residentialAddress"
                type={FieldType.Input}
                label=""
                containerClassName="w-52 !mt-0"
                defaultValue={`${staffProfile.residentialAddressBuildingNo} ${staffProfile.residentialAddressStreetName}, ${staffProfile.residentialAddressSuburb}, ${staffProfile.residentialAddressState} ${staffProfile.residentialAddressZip}`}
                isDisabled={true}
              />
              <span className="mx-3">Occupation</span>
              <Input
                name="occupation"
                type={FieldType.Input}
                label=""
                containerClassName="w-52 !mt-0"
                defaultValue={staffProfile.occupation}
                isDisabled={true}
              />
              <span className="mx-3">declare that:</span>
            </div>
            <div>
              <div className="flex items-start">
                <Checkbox
                  name="healthTermsUnderstood"
                  label="Read and understood *"
                  additionalText="I have read and understood the Workplace injury and
                    Rehabilitation Act 2013, Section 41 and the advertised
                    position description, and have discussed the employment with
                    EICARE Pty. Ltd. I understand the responsibilities and
                    physical demands of the employment."
                  type={FieldType.Checbox}
                  className="mr-3 mt-5"
                  requiredCondition="This is a required field"
                  isHiglighted={isToggled}
                  defaultValue={staffProfile.healthTermsUnderstood}
                  labelClassName="text-gray-900 text-medium text-sm"
                />
              </div>
              <div className="flex items-start">
                <Checkbox
                  name="termsPreExisting"
                  label="Pre-existing disclosure *"
                  additionalText="I acknowledge that I am required to disclose all
                    pre-existing conditions which I believe may be affected by
                    me undertaking the employment."
                  type={FieldType.Checbox}
                  className="mr-3 mt-5"
                  defaultValue={staffProfile.termsPreExisting}
                  labelClassName="text-gray-900 text-medium text-sm"
                  requiredCondition="This is a required field"
                  isHiglighted={isToggled}
                />
              </div>
              <div className="flex items-start">
                <Checkbox
                  name="termsFailureToDisclose"
                  label="Failure to disclose *"
                  additionalText="I acknowledge that failure to disclose this information or
                    providing false and misleading information may result in
                    invoking Section 41 of Workplace Injury and Rehabilitation
                    Act 2013 which may disentitle me or my dependents from
                    receiving any worker’s compensation benefits relating to any
                    recurrence, aggravation, acceleration, exacerbation or
                    deterioration of any pre-existing condition which I may have
                    arising out of or in the course of, the employment."
                  type={FieldType.Checbox}
                  className="mr-3 mt-5"
                  requiredCondition="This is a required field"
                  isHiglighted={isToggled}
                  defaultValue={staffProfile.termsFailureToDisclose}
                  labelClassName="text-gray-900 text-medium text-sm"
                />
              </div>
            </div>
            <div className="mt-5">
              <h6 className="text-gray-900 text-sm font-medium">
                Declared statement *
              </h6>
              <div className="ml-7">
                <Radio
                  name="termsDeclarationPreExisting"
                  type={FieldType.Radio}
                  requiredCondition="This is a required field"
                  isHiglighted={isToggled}
                  label=""
                  options={[
                    {
                      label:
                        "I have suffered no prior injuries that may recur or deteriorate, accelerate or be exacerbated or aggravated by the employment.",
                      value: "no",
                    },
                    {
                      label:
                        "I have suffered the following conditions that may recur or deteriorate, accelerate or be exacerbated or aggravated by the employment.",
                      value: "yes",
                    },
                  ]}
                  isCol={true}
                  className="text-sm font-normal"
                  defaultValue={
                    staffProfile
                      ? staffProfile.termsDeclarationPreExisting
                        ? "yes"
                        : "no"
                      : undefined
                  }
                />
                {(termsDeclarationPreExisting === "yes" ||
                  staffProfile.termsDeclarationPreExisting) && (
                  <Textarea
                    name="termsDeclaredStatement"
                    type={FieldType.Textarea}
                    className="w-2/3"
                    label=""
                    requiredCondition={"This is a required field"}
                    defaultValue={staffProfile.termsDeclaredStatement}
                    isHiglighted={isToggled}
                  />
                )}
              </div>
            </div>
            <div className="">
              <div className="flex items-start">
                <Checkbox
                  name="termsDeclarationAcknowledgment"
                  label="Declare and acknowledge statement *"
                  type={FieldType.Checbox}
                  additionalText="I acknowledge and declare that the information provided in
                    this form is true and correct in every particular."
                  className="mr-3 mt-5"
                  requiredCondition="This is a required field"
                  isHiglighted={isToggled}
                  defaultValue={staffProfile.termsDeclarationAcknowledgment}
                  labelClassName="text-gray-900 text-medium text-sm"
                />
              </div>
            </div>
            <div className="mt-5 flex item-center justify-between flex-col md:flex-row">
              <Sign
                name="signedAt"
                label="Health declaration signature *"
                type={FieldType.Sign}
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                inputClassName="w-3/4"
                defaultValue={staffProfile.signedAt}
                defaultSignature={staffProfile.signature}
                signatureName="signature"
              />
              <Notification
                type="info"
                message="Helpful information"
                bodyText="Did you spell your name incorrectly? Click the “Re-sign” button to sign again"
                className="w-3/4 md:w-1/4 !text-primary-800"
                size={48}
                textClassName="!text-primary-800"
              />
            </div>
            <div>
              <h3 className="text-gray-900 text-lg font-semibold">
                2 - Immunisation history and Covid-19 vaccine
              </h3>
            </div>
            <div className="flex item-center justify-between flex-col md:flex-row">
              <FileUpload
                name="immunisationHistory"
                label="Immunisation history"
                buttonLabel="Upload Immunisation history"
                type={FieldType.FileUpload}
                fileUploadContainerClassName="md:w-1/2 w-full pr-6"
                key="immunisationHistory"
                isMultipleFiles={false}
                defaultValue={staffProfile.immunisationHistory}
                externalLink="https://www.servicesaustralia.gov.au/how-to-get-immunisation-history-statements?context=22436"
              />
              <FileUpload
                name="covidVaccineCertificate"
                label="Covid-19 vaccine certificate"
                buttonLabel="Upload covid-19 vaccine certificate"
                type={FieldType.FileUpload}
                fileUploadContainerClassName="md:w-1/2 w-full  pr-6"
                key="covidVaccineCertificate"
                isMultipleFiles={false}
                defaultValue={staffProfile.covidVaccineCertificate}
                externalLink="https://www.health.gov.au/our-work/covid-19-vaccines/getting-your-vaccination"
              />
            </div>
            <div className="mt-5">
              <h3 className="text-gray-900 text-lg font-semibold">
                3 - Emergency contact
              </h3>
              <Select
                name="emergencyContact.title"
                label="Title *"
                type={FieldType.Select}
                placeholder="Select"
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                options={[
                  { label: "Mr", value: "Mr" },
                  {
                    label: "Mrs",
                    value: "Mrs",
                  },
                  { label: "Miss", value: "Miss" },
                ]}
                containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                className="w-full"
                defaultValue={staffProfile?.emergencyContact?.title}
                hideSearch
              />
              <Input
                name="emergencyContact.firstName"
                label="First Name *"
                type={FieldType.Input}
                containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                className="w-full"
                placeholder="John"
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                defaultValue={staffProfile?.emergencyContact?.firstName}
              />
              <Input
                name="emergencyContact.lastName"
                label="Last Name *"
                type={FieldType.Input}
                placeholder="Smith"
                containerClassName="md:w-1/3 w-full  inline-block align-top md:pr-6"
                className="w-full"
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                defaultValue={staffProfile?.emergencyContact?.lastName}
              />
              <EmailInput
                name="emergencyContact.email"
                label="Email *"
                type={FieldType.EmailInput}
                placeholder="johndoe@gmail.com"
                containerClassName="md:w-1/4 w-full  inline-block align-top md:pr-6"
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                defaultValue={staffProfile?.emergencyContact?.email}
              />
              <Input
                name="emergencyContact.mobile"
                label="Mobile *"
                type={FieldType.Input}
                placeholder="04XXX XXX XXX"
                containerClassName="md:w-1/4 w-full  inline-block align-top md:pr-6"
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                defaultValue={staffProfile?.emergencyContact?.mobile}
                validationFunction={validateAustralianPhoneNumber}
                validationErrorMessage="Invalid Australian phone number"
              />
              <Input
                name="emergencyContact.homeTelephoneNo"
                label="Home Telephone no"
                type={FieldType.Input}
                placeholder="+614XXX XXX XXX"
                containerClassName="md:w-1/4 w-full  inline-block align-top md:pr-6"
                defaultValue={staffProfile?.emergencyContact?.homeTelephoneNo}
                validationFunction={validateAustralianTelephoneNumber}
                validationErrorMessage="Invalid Australian telephone number"
                requiredCondition={
                  Boolean(homePhone) && "Invalid Australian telephone number"
                }
                additionalTriggerRequired
              />
              <Input
                name="emergencyContact.relationShip"
                label="Relationship *"
                type={FieldType.Input}
                placeholder="Uncle"
                containerClassName="md:w-1/4 w-full inline-block align-top md:pr-6"
                requiredCondition="This is a required field"
                isHiglighted={isToggled}
                defaultValue={staffProfile?.emergencyContact?.relationShip}
              />
            </div>
          </div>
          <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full flex-wrap md:flex-nowrap">
            <Button
              variant="primary"
              type="submit"
              form={`currentStaffDocument-3`}
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
