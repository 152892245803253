import { redirect } from "react-router-dom";

export async function MyAccountLoader({ params, request, user }: any) {
  const authData = window.localStorage.getItem("eicare_auth");
  const auth = JSON.parse(authData || "{}");
  if (!(auth && auth.session)) {
    const url = new URL(request.url);
    return redirect(
      `/login?redirectTo=${encodeURIComponent(url.pathname + url.search)}`
    );
  }
  return {
    user,
  };
}
