import { createdAccountDocumentUpdate } from "api/staff";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import FileUpload from "components/FileUpload";
import GenericAccordion from "components/GenericAccordion";
import Notification, { NotificationProps } from "components/Notification";
import Sign from "components/Sign";
import { FieldType, FormData } from "components/types";
import { handleResponse } from "components/utility";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  getPersonalSpecificationBody,
  getPositionDescriptionBody,
} from "routes/onboarding/staff/steps/step10";
import { CurrentEmployeeProps } from "./CurrentEmployeeStep1";

const FIELDS_TO_REMOVE = ["calendarMonth", "calendarYear"];

export default function CurrentEmployeeStep9({
  user,
  refreshUser,
  isToggled,
}: CurrentEmployeeProps) {
  const { formState, handleSubmit, setError, getValues, ...formMethods } =
    useForm<FormData>();
  const { staffProfile } = user;
  const [response, setResponse] = useState<NotificationProps | null>(null);
  const methods = {
    ...formMethods,
    formState,
    handleSubmit,
    setError,
    getValues,
  };

  const onSubmit = async (data: FormData) => {
    setResponse(null);
    data.contractRecievedDate = staffProfile.contractReceivedDate || new Date();
    const response = await createdAccountDocumentUpdate(
      9,
      data,
      FIELDS_TO_REMOVE
    );
    const isSuccess = await handleResponse(
      response,
      setError,
      setResponse,
      "Submitted successfully"
    );
    isSuccess && (await refreshUser());
  };

  const getAdditionalFieldsForPersonalCareSupportWorker = () => {
    return (
      <>
        {getPersonalCareDescription()}
        {getDataForStaffToFill()}
      </>
    );
  };

  const getPersonalCareDescription = () => {
    return (
      <div className="py-6">
        <h4 className="text-base leading-7 font-semibold text-gray-900">
          Job Description
        </h4>
        <div className="flex space-x-12 pt-3">
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Job title
            </span>
            <span className="text-sm leading-5 font-medium text-gray-900">
              Personal Care Worker
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
              Reports to Manager
            </span>
            <span className="text-sm leading-5 font-medium text-gray-900">
              {staffProfile.contractReportsToManager &&
                `${
                  staffProfile.contractReportsToManager.firstName +
                  " " +
                  staffProfile.contractReportsToManager.lastName
                }`}
            </span>
          </div>
        </div>
        <div className="mt-6 px-3 shadow-md">
          <GenericAccordion
            title="Position Description"
            children={getPositionDescriptionBody()}
          />
          <GenericAccordion
            title="Personal Specification"
            children={getPersonalSpecificationBody()}
            isLastRecord
          />
        </div>
      </div>
    );
  };

  const getDataForStaffToFill = () => {
    return (
      <div className="">
        <div className="mt-6 rounded-md shadow-md">
          <div className="p-6  space-x-6 w-full flex items-start">
            <div className="w-1/2">
              <Checkbox
                name="contractAcknowledged"
                label="Acknowledgement"
                defaultValue={staffProfile.contractAcknowledged}
                type={FieldType.Checbox}
                additionalText="I certify that I have read, understood and accept the duties,responsibilities and obligations of my position."
              />
            </div>
            <div className="w-1/2">
              <Sign
                name="contractAcknowledgedAt"
                label="Employee signature *"
                type={FieldType.Sign}
                defaultValue={staffProfile.contractAcknowledgedAt}
                defaultSignature={staffProfile.contractAcknowledgedSignature}
                signatureName="contractAcknowledgedSignature"
                dataContaineClassName="flex-col"
                successMessageClassName="!flex-row space-x-1 mt-5"
                signInputClassName="w-105"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="bg-white shadow rounded-md">
      <div className="text-sm font-medium">
        <div className="flex flex-col ml-5">
          {response ? (
            <Notification
              type={response.type}
              message={response.message}
              className="!m-0 !mt-2 !inline-flex w-max"
            />
          ) : (
            <></>
          )}
        </div>
        <FormProvider {...methods}>
          <form id={"currentStaffDocument-9"} onSubmit={handleSubmit(onSubmit)}>
            <div className="p-6">
              <div className="py-6">
                <h3 className="text-lg leading-6 font-semibold text-gray-900">
                  Please wait to receive your contract from EICARE. Once
                  received, sign and upload below.
                </h3>
                <FileUpload
                  name="contractSigned"
                  label="Contract *"
                  type={FieldType.FileUpload}
                  requiredCondition="This is a required field"
                  isHiglighted={isToggled}
                  defaultValue={staffProfile.contractSigned}
                  isMultipleFiles={false}
                  buttonLabel="Upload signed contract"
                  fileUploadContainerClassName="pr-1 w-3/4"
                />
              </div>
              {staffProfile.isPersonalCareWorker &&
                getAdditionalFieldsForPersonalCareSupportWorker()}
            </div>
            <div className="bg-gray-50 flex justify-end space-x-2 items-center  rounded-b-md px-5 py-3 border-t border-gray-200 w-full flex-wrap md:flex-nowrap">
              <Button
                variant="primary"
                type="submit"
                form={`currentStaffDocument-9`}
              >
                Submit
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
