import { Staff } from "api/types";
import { useGetStaff } from "api/user";
import Badge from "components/Badge";
import Button from "components/Button";
import { Icon } from "components/Icon";
import Notification from "components/Notification";
import Pagination from "components/Pagination";
import RightDrawer from "components/RightDrawer";
import { SortOptions } from "components/SortableTable";
import {
  getAvatarName,
  getProfileStatusBadgeProps,
} from "components/StaffDetail";
import StickyTable, { StickyTableColumn } from "components/StickyTable";
import { FormData } from "components/types";
import UserDetailsContent, { Section } from "components/UserDetailsContent";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { handleViewProfile } from "routes/dashboard/ExpiringDocuments";
import { StaffFilters } from "routes/dashboard/staff/AllStaff";
import { cleanObject } from "utils/util";
import { UnionTypesForStaff } from "./EmploymentChecksRegister";

const columns: StickyTableColumn<Staff>[] = [
  {
    title: "Name",
    group: "Personal Details",
    dataIndex: "name",
    sortable: true,
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.firstName ? (
        <div
          className="flex items-center justify-start w-full cursor-pointer"
          onClick={() => handleViewProfile(row.id)}
        >
          <Avatar
            name={getAvatarName(row)}
            size="32"
            round
            textSizeRatio={2.28}
            className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
            color="#D1F5FC"
            fgColor="#194860"
            src={row.avatar}
          />
          <div className="flex flex-col items-start ml-4 w-36">
            <span className="underline text-sm leading-5 font-semibold text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap text-left w-36">{`${row.firstName} ${row.lastName}`}</span>
          </div>
        </div>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Profile status",
    group: "Personal Details",
    dataIndex: "status",
    Cell: (value: UnionTypesForStaff, row: Staff) => {
      const props = getProfileStatusBadgeProps(row.status);
      props.className = `${props.className} !inline-flex`;
      return <Badge {...props} />;
    },
  },
  { title: "e-mail", group: "Personal Details", dataIndex: "email" },
  {
    title: "mobile",
    group: "Personal Details",
    dataIndex: "mobile",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) => (
      <span className="text-sm leading-5 font-normal text-gray-700">
        {row.mobile ? row.mobile : "N/A"}
      </span>
    ),
  },
  {
    title: "Title",
    group: "Emergency contact",
    dataIndex: "staffProfile?.emergencyContact?.title",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.emergencyContact?.title ? (
        <span className="text-sm leading-5 font-normal text-gray-700">
          {row.staffProfile?.emergencyContact?.title}
        </span>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "name",
    group: "Emergency contact",
    dataIndex: "staffProfile?.emergencyContact?.firstName",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.emergencyContact?.firstName ? (
        <span className="text-sm leading-5 font-normal text-gray-700">{`${row.staffProfile?.emergencyContact?.firstName} ${row.staffProfile?.emergencyContact?.lastName}`}</span>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Relationship",
    group: "Emergency contact",
    dataIndex: "staffProfile?.emergencyContact?.relationShip",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.emergencyContact?.title ? (
        <span className="text-sm leading-5 font-normal text-gray-700">
          {row.staffProfile?.emergencyContact?.relationShip}
        </span>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Phone number",
    group: "Emergency contact",
    dataIndex: "staffProfile?.emergencyContact?.mobile",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.emergencyContact?.mobile ? (
        <span className="text-sm leading-5 font-normal text-gray-700">
          {row.staffProfile?.emergencyContact?.mobile}
        </span>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Home Telephone number",
    group: "Emergency contact",
    dataIndex: "staffProfile?.emergencyContact?.homeTelephoneNo",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.emergencyContact?.homeTelephoneNo ? (
        <span className="text-sm leading-5 font-normal text-gray-700">
          {row.staffProfile?.emergencyContact?.homeTelephoneNo}
        </span>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Email",
    group: "Emergency contact",
    dataIndex: "staffProfile?.emergencyContact?.email",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.emergencyContact?.email ? (
        <span className="text-sm leading-5 font-normal text-gray-700">
          {row.staffProfile?.emergencyContact?.email}
        </span>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "",
    dataIndex: "actions",
    group: "Actions",
    isSticky: true,
    columnStyle: "border-l border-gray-300",
  },
];

export default function EmergencyContactDetails() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [isError, setIsError] = useState(false);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [sortConfig, setSortConfig] = useState<SortOptions<Staff> | null>(null);
  const navigate = useNavigate();

  const defaultFilter: Partial<StaffFilters> = {
    take: "10",
    page: "1",
  };
  const [filters, setFilters] = useState({ ...defaultFilter });
  const { handleSubmit, setValue, getValues, watch, reset, ...formMethods } =
    useForm<FormData>();

  const { data, isFetching } = useGetStaff(
    true,
    cleanObject({
      ...filters,
    })
  );
  useEffect(() => {
    if (data) {
      if (Array.isArray(data.staffs) && data.staffs) {
        setStaff(data.staffs);
        setTotalItems(data.count);
        return;
      }
      setIsError(true);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (sortConfig) {
      setFilters({
        page: "1",
        take: itemsPerPage.toString(),
        order_by: "firstName",
        order: sortConfig.sortOrder,
      });
      setCurrentPage(1);
    }
  }, [sortConfig, itemsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilters({ ...filters, page: page.toString() });
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setFilters({ ...filters, page: (currentPage + 1).toString() });
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setFilters({ ...filters, page: (currentPage - 1).toString() });
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page whenever items per page change
    setFilters({ ...filters, page: "1", take: newItemsPerPage.toString() });
  };

  const defaultSections: Section[] | undefined = selectedStaff
    ? [
        {
          title: "Emergency contact",
          details: [
            {
              label: "Title",
              value: (
                <span className="text-sm leading-5 font-normal text-gray-700">
                  {selectedStaff.staffProfile?.emergencyContact?.title
                    ? selectedStaff.staffProfile?.emergencyContact?.title
                    : "N/A"}
                </span>
              ),
            },
            {
              label: "name",
              value: (
                <span className="text-sm leading-5 font-normal text-gray-700">
                  {selectedStaff.staffProfile?.emergencyContact?.firstName
                    ? `${selectedStaff.staffProfile?.emergencyContact?.firstName} ${selectedStaff.staffProfile?.emergencyContact?.lastName}`
                    : "N/A"}
                </span>
              ),
            },
            {
              label: "Relationship",
              value: (
                <span className="text-sm leading-5 font-normal text-gray-700">
                  {selectedStaff.staffProfile?.emergencyContact?.relationShip
                    ? selectedStaff.staffProfile?.emergencyContact?.relationShip
                    : "N/A"}
                </span>
              ),
            },
            {
              label: "Phone number",
              value: (
                <span className="text-sm leading-5 font-normal text-gray-700">
                  {selectedStaff.staffProfile?.emergencyContact?.mobile
                    ? selectedStaff.staffProfile?.emergencyContact?.mobile
                    : "N/A"}
                </span>
              ),
            },
            {
              label: "Home Telephone number",
              value: (
                <span className="text-sm leading-5 font-normal text-gray-700">
                  {selectedStaff.staffProfile?.emergencyContact?.homeTelephoneNo
                    ? selectedStaff.staffProfile?.emergencyContact
                        ?.homeTelephoneNo
                    : "N/A"}
                </span>
              ),
            },
            {
              label: "Email",
              value: (
                <span className="text-sm leading-5 font-normal text-gray-700">
                  {selectedStaff.staffProfile?.emergencyContact?.email
                    ? selectedStaff.staffProfile?.emergencyContact?.email
                    : "N/A"}
                </span>
              ),
            },
          ],
        },
      ]
    : undefined;

  const updatedData = staff.map((dt) => ({
    ...dt,
    actions: (
      <Button
        variant="text"
        onClick={() => {
          setIsDrawerOpen(true);
          setSelectedStaff(dt);
        }}
      >
        View
      </Button>
    ),
  }));

  if (isError) {
    return (
      <Notification
        type="error"
        message={"Error in fetching data from server"}
      />
    );
  }
  return (
    <div className="">
      <h2 className="text-xl leading-7 font-semibold">
        Emergency Contact Details
      </h2>
      <div className="mt-6 shadow-md rounded-md border border-gray-200">
        <StickyTable<Staff>
          columns={columns}
          data={updatedData}
          setSortConfig={setSortConfig}
          sortConfig={sortConfig}
          containerClassName="rounded-t-md"
          hasStickyColumn={true}
        />
        <RightDrawer
          isOpen={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            setSelectedStaff(null);
          }}
          title="User Details"
          additionalHeaderInformation={
            selectedStaff && (
              <div className="mr-5 space-x-2 flex justify-center items-center">
                <span className="text-xs leading-4 font-medium tracking-wider uppercase text-gray-700">
                  PROFILE STATUS:
                </span>
                <Badge {...getProfileStatusBadgeProps(selectedStaff.status)} />
              </div>
            )
          }
        >
          {selectedStaff && (
            <UserDetailsContent
              staff={{ ...selectedStaff }}
              otherSections={defaultSections}
              containerClassName="mb-6"
            />
          )}
        </RightDrawer>
        <div className="px-6 pb-4">
          <FormProvider
            {...{
              ...formMethods,
              handleSubmit,
              setValue,
              getValues,
              reset,
              watch,
            }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
            />
          </FormProvider>
        </div>
      </div>
      <div className="mt-3 py-3 border-t border-gray-200">
        <Button
          variant="gray"
          onClick={() => navigate(`/dashboard/staff`)}
          className="flex justify-center items-center py-3 pl-3"
          size="noSize"
        >
          <Icon name="back" />
          <span className="ml-2 text-gray-500 text-sm mr-3 font-semibold">
            Go back to Staff dashboard
          </span>
        </Button>
      </div>
    </div>
  );
}
