import { StaffProfile, User } from "api/types";
import classNames from "classnames";
import Badge from "components/Badge";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import GenericAccordion from "components/GenericAccordion";
import {
  checkExpiry,
  getAvatarName,
  getContentByExpiry,
  getPropsForExpiry,
} from "components/StaffDetail";
import { FieldType, FormData } from "components/types";
import Avatar from "react-avatar";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import {
  getPersonalSpecificationBody,
  getPositionDescriptionBody,
} from "routes/onboarding/staff/steps/step10";
import {
  AUSTRALIA_STATE_OPTIONS,
  capitalizeFirstLetter,
  formatDate,
  formatDateWithTime,
  getFormattedDate,
  isBoolean,
  validateAustralianPhoneNumber,
  validateAustralianTelephoneNumber,
} from "../../utils/util";

export const getPersonalInformationData = (user: User) => [
  [
    {
      title: "",
      value: (
        <div className="flex items-center">
          <Avatar
            name={getAvatarName(user)}
            size="40"
            round
            textSizeRatio={2.65}
            className="!h-12 !w-12 !text-base !leading-6 !font-normal !text-primary-900"
            color="#D1F5FC"
            fgColor="#194860"
            src={user.avatar}
          />
          <div className="flex flex-col items-start ml-4 w-64">
            <span className="text-xl leading-7 font-semibold  text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap text-left w-64">{`${user.title} ${user.firstName} ${user.lastName}`}</span>
          </div>
        </div>
      ),
      cellClassName: "!w-[40%]",
    },
    {
      title: "Occupation",
      value: capitalizeFirstLetter(user.staffProfile?.occupation),
      cellClassName: "!w-[30%]",
    },
    {
      title: "Date joined EICare",
      value: formatDate(new Date(user.staffProfile.employmentStartDate)),
      cellClassName: "!w-[30%]",
    },
  ],
];
export const getContactData = (user: User) => [
  [
    {
      title: "email",
      value: user.email || "N/A",
      columnWidth: 3,
    },
    { title: "mobile number", value: user?.mobile || "N/A", columnWidth: 3 },
    {
      title: "Home telephone number",
      value: user.staffProfile?.homePhone || "N/A",
      columnWidth: 3,
    },
  ],
  [
    {
      title: "Residential address",
      value: user.staffProfile.residentialAddressStreetName
        ? capitalizeFirstLetter(
            `${user.staffProfile.residentialAddressBuildingNo} ${user.staffProfile.residentialAddressStreetName}, ${user.staffProfile.residentialAddressSuburb}, ${user.staffProfile.residentialAddressState} ${user.staffProfile.residentialAddressZip}`
          )
        : "N/A",
      columnWidth: 3,
    },
    {
      title: "Mailing addresss",
      value: user.staffProfile.mailingAddressStreetName
        ? capitalizeFirstLetter(
            `${user.staffProfile.mailingAddressBuildingNo} ${user.staffProfile.mailingAddressStreetName}, ${user.staffProfile.mailingAddressSuburb}, ${user.staffProfile.mailingAddressState} ${user.staffProfile.mailingAddressZip}`
          )
        : "N/A",
      columnWidth: 3,
    },
  ],
];
export const getEmergencyContactData = (emergencyContact: User) => [
  [
    {
      title: "",
      value: (
        <div className="flex flex-col items-start w-64">
          <span className="text-xl leading-7 font-semibold  text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap text-left w-64">{`${emergencyContact?.title || "-"} ${emergencyContact?.firstName || "-"} ${emergencyContact?.lastName || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "email",
      value: emergencyContact?.email || "N/A",
    },
    {
      title: "mobile number",
      value: emergencyContact?.mobile || "N/A",
    },
  ],
  [
    {
      title: "",
      value: "",
    },
    {
      title: "Home telephone number",
      value: emergencyContact?.homeTelephoneNo || "N/A",
    },
    {
      title: "Relationship",
      value: capitalizeFirstLetter(emergencyContact?.relationShip) || "N/A",
    },
  ],
];
export const getEssentialDocumentData = (user: User) => [
  [
    {
      title: "",
      value: (
        <div className="flex flex-col items-start">
          <div
            className="text-gray-500"
            data-tooltip-id="policeCheckValidUntil"
            data-tooltip-content={getContentByExpiry(
              checkExpiry(user.staffProfile?.policeCheckValidUntil)
            )}
          >
            <Badge
              {...getPropsForExpiry(
                checkExpiry(user.staffProfile?.policeCheckValidUntil),
                "Police check expiry"
              )}
            />
            <Tooltip id="policeCheckValidUntil" />
          </div>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {user.staffProfile?.policeCheckValidUntil
              ? formatDate(new Date(user.staffProfile?.policeCheckValidUntil))
              : "N/A"}
          </span>
        </div>
      ),
      cellClassName: "!w-[33.33%]",
    },
    {
      title: "",
      value: (
        <div className="flex flex-col items-start">
          <div
            className="text-gray-500"
            data-tooltip-id="visaExpiryDate"
            data-tooltip-content={getContentByExpiry(
              checkExpiry(user.staffProfile?.visaExpiryDate)
            )}
          >
            <Badge
              {...getPropsForExpiry(
                checkExpiry(user.staffProfile?.visaExpiryDate),
                "visa expiry date"
              )}
            />
            <Tooltip id="visaExpiryDate" />
          </div>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {user.staffProfile?.visaExpiryDate
              ? formatDate(new Date(user.staffProfile.visaExpiryDate))
              : "N/A"}
          </span>
        </div>
      ),
      cellClassName: "!w-[33.33%]",
    },
    {
      title: "",
      value: (
        <div className="flex flex-col items-start">
          <div
            className="text-gray-500"
            data-tooltip-id="passportExpiryDate"
            data-tooltip-content={getContentByExpiry(
              checkExpiry(user.staffProfile?.passportExpiryDate)
            )}
          >
            <Badge
              {...getPropsForExpiry(
                checkExpiry(user.staffProfile?.passportExpiryDate),
                "Passport expiry"
              )}
            />
            <Tooltip id="passportExpiryDate" />
          </div>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {user.staffProfile?.passportExpiryDate
              ? formatDate(new Date(user.staffProfile?.passportExpiryDate))
              : "N/A"}
          </span>
        </div>
      ),
      cellClassName: "!w-[33.33%]",
    },
  ],
  [
    {
      title: "",
      value: (
        <div className="flex flex-col items-start">
          <span className="flex justify-between">
            <div
              className="text-gray-500"
              data-tooltip-id="vehicleRegistrationExpiry"
              data-tooltip-content={getContentByExpiry(
                checkExpiry(
                  user.staffProfile?.vehicleDetails?.vehicleRegistrationExpiry
                )
              )}
            >
              <Badge
                {...getPropsForExpiry(
                  checkExpiry(
                    user.staffProfile?.vehicleDetails?.vehicleRegistrationExpiry
                  ),
                  "Rego expiry"
                )}
              />
              <Tooltip id="vehicleRegistrationExpiry" />
            </div>
          </span>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {user.staffProfile?.vehicleDetails?.vehicleRegistrationExpiry
              ? formatDate(
                  new Date(
                    user.staffProfile?.vehicleDetails?.vehicleRegistrationExpiry
                  )
                )
              : "N/A"}
          </span>
        </div>
      ),
      cellClassName: "!w-[33.33%]",
    },
    {
      title: "",
      value: (
        <div className="flex flex-col items-start">
          <div
            className="text-gray-500"
            data-tooltip-id="vehicleInsuranceExpiry"
            data-tooltip-content={getContentByExpiry(
              checkExpiry(
                user.staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
              )
            )}
          >
            <Badge
              {...getPropsForExpiry(
                checkExpiry(
                  user.staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
                ),
                "Vehicle insurance expiry"
              )}
            />
            <Tooltip id="vehicleInsuranceExpiry" />
          </div>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {user.staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
              ? formatDate(
                  new Date(
                    user.staffProfile?.vehicleDetails?.vehicleInsuranceExpiry
                  )
                )
              : "N/A"}
          </span>
        </div>
      ),
      cellClassName: "!w-[33.33%]",
    },
    {
      title: "",
      value: (
        <div className="flex flex-col items-start">
          <div
            className="text-gray-500"
            data-tooltip-id="drivingLicenseExpiry"
            data-tooltip-content={getContentByExpiry(
              checkExpiry(
                user.staffProfile?.vehicleDetails?.drivingLicenseExpiry
              )
            )}
          >
            <Badge
              {...getPropsForExpiry(
                checkExpiry(
                  user.staffProfile?.vehicleDetails?.drivingLicenseExpiry
                ),
                "Driving licence expiry"
              )}
            />
            <Tooltip id="drivingLicenseExpiry" />
          </div>
          <span className="text-sm leading-5 font-medium text-gray-900">
            {user.staffProfile?.vehicleDetails?.drivingLicenseExpiry
              ? formatDate(
                  new Date(
                    user.staffProfile?.vehicleDetails?.drivingLicenseExpiry
                  )
                )
              : "N/A"}
          </span>
        </div>
      ),
      cellClassName: "!w-[33.33%]",
    },
  ],
];
export const getTrainingData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Highest qualification in your country",
      value: capitalizeFirstLetter(staffProfile?.highestQualification) || "N/A",
    },
    {
      title: "School for highest qualification",
      value:
        capitalizeFirstLetter(staffProfile?.highestQualificationSchool) ||
        "N/A",
    },
    {
      title: "Work-related certificates",
      value: staffProfile?.workCertificates
        ? staffProfile?.workCertificates
        : "N/A",
      isTypeFile: Boolean(staffProfile?.workCertificates),
    },
  ],
  [
    {
      title: "Food handling certificate",
      value: staffProfile?.foodHandlingCertificate
        ? staffProfile?.foodHandlingCertificate
        : "N/A",
      isTypeFile: Boolean(staffProfile?.foodHandlingCertificate),
    },
    {
      title: "Infection control training",
      value: staffProfile?.infectionControlCertificate
        ? staffProfile?.infectionControlCertificate
        : "N/A",
      isTypeFile: Boolean(staffProfile?.infectionControlCertificate),
    },
    {
      title: "Resume",
      value: staffProfile?.resume ? [staffProfile?.resume] : "N/A",
      isTypeFile: Boolean(staffProfile?.resume),
    },
  ],
  [
    {
      title: "Aged care certificate (AHPRA)",
      value: staffProfile?.ahpra ? [staffProfile?.ahpra] : "N/A",
      isTypeFile: Boolean(staffProfile?.ahpra),
      columnWidth: 3,
    },
    {
      title: "Aged care certificate (AHPRA) date",
      value: Boolean(staffProfile.ahpraDate)
        ? formatDate(new Date(staffProfile.ahpraDate))
        : "N/A",
      columnWidth: 3,
    },
  ],
];
export const getCodeOfConductData = (staffProfile: StaffProfile) => [
  [
    {
      title: "Employee handboook",
      value: (
        <Button variant="primary" size="base" className="text-center">
          <a
            href="https://d1xirsxbrln77c.cloudfront.net/static/docs/employee_handbook.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm leading-5 font-semibold"
          >
            View Employee handbook
          </a>
        </Button>
      ),
    },
    {
      title: "Code of Conduct acknowledgment",
      value: isBoolean(staffProfile.acknowledgeCodeOfConduct)
        ? "I have read and agree to the Code of conduct"
        : "N/A",
    },
  ],
  [
    {
      title: "Signed",
      value: staffProfile?.signatureCodeOfConduct || "N/A",
    },
    {
      title: "Signed date",
      value: staffProfile?.signatureCodeOfConductDate
        ? getFormattedDate(new Date(staffProfile?.signatureCodeOfConductDate))
        : "N/A",
    },
  ],
];
export const getContractData = (staffProfile: StaffProfile) => [
  [
    {
      title: "signed contract",
      value: staffProfile?.contractSigned
        ? [staffProfile?.contractSigned]
        : "N/A",
      isTypeFile: Boolean(staffProfile?.contractSigned),
    },
    {
      title: "Contract received by EICARE date",
      value: staffProfile?.contractReceivedDate
        ? formatDateWithTime(new Date(staffProfile?.contractReceivedDate))
        : "N/A",
    },
    {
      title: "Reports to Manager/Supervisor",
      value: staffProfile?.reportingManager
        ? staffProfile.reportingManager.email
        : "N/A",
    },
  ],
  [
    {
      title: "",
      value: (
        <div className="my-6 px-3 shadow-md">
          <GenericAccordion
            title="Position description"
            children={getPositionDescriptionBody()}
          />
          <GenericAccordion
            title="Personal specification"
            children={getPersonalSpecificationBody()}
            isLastRecord
          />
        </div>
      ),
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
    },
  ],
  [
    {
      title: "Acknowledgement",
      value: staffProfile.contractAcknowledged
        ? "I certify that I have read, understood and accept the duties,responsibilities and obligations of my position."
        : "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
    },
  ],
  [
    {
      title: "Employee Signature",
      value: staffProfile.contractAcknowledgedSignature || "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 3,
    },
    {
      title: "Employee signed date",
      value: staffProfile?.contractAcknowledgedAt
        ? formatDateWithTime(new Date(staffProfile.contractAcknowledgedAt))
        : "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
      columnWidth: 3,
    },
  ],
  [
    {
      title: "Manager Name",
      value: staffProfile?.contractReportsToManager?.firstName
        ? capitalizeFirstLetter(
            `${staffProfile?.contractReportsToManager.firstName} ${staffProfile?.contractReportsToManager.lastName}`
          )
        : "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
    },
    {
      title: "Manager signature",
      value: staffProfile.contractAdminSignature || "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
    },
    {
      title: "Manager signed date",
      value: staffProfile?.contractAdminSignedAt
        ? formatDateWithTime(new Date(staffProfile.contractAdminSignedAt))
        : "N/A",
      cellClassName: staffProfile.isPersonalCareWorker ? "" : "hidden",
    },
  ],
];
export const editEmergencyContactDetail = (
  staffProfile: StaffProfile,
  watchedFields: FormData
) => [
  {
    name: "emergencyContact.title",
    label: "Title *",
    type: FieldType.Select,
    placeholder: "Select",
    containerClassName: "!w-full",
    options: [
      { label: "Mr", value: "Mr" },
      {
        label: "Mrs",
        value: "Mrs",
      },
      { label: "Miss", value: "Miss" },
    ],
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.emergencyContact?.title,
    requiredCondition: "This is a required field",
    inputClassName: "w-3/4",
    hideSearch: true,
  },
  {
    name: "emergencyContact.firstName",
    label: "First Name *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "John",
    defaultValue: staffProfile?.emergencyContact?.firstName,
    requiredCondition: "This is a required field",
  },
  {
    name: "emergencyContact.lastName",
    label: "Last Name *",
    type: FieldType.Input,
    placeholder: "John",
    parentFormContainerClassName: "w-full md:w-1/3  inline-block align-top",
    defaultValue: staffProfile.emergencyContact?.lastName,
    requiredCondition: "This is a required field",
  },
  {
    name: `emergencyContact.email`,
    label: "Email *",
    type: FieldType.EmailInput,
    placeholder: "jamesbond@actionstuff.com",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.emergencyContact?.email,
    requiredCondition: "This is a required field",
  },
  {
    name: "emergencyContact.mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.emergencyContact?.mobile,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
    requiredCondition: "This is a required field",
  },
  {
    name: "emergencyContact.homeTelephoneNo",
    label: "Home Telephone no",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: staffProfile?.emergencyContact?.homeTelephoneNo,
    validationFunction: validateAustralianTelephoneNumber,
    validationErrorMessage: "Invalid Australian telephone number",
    additionalTriggerRequired: true,
    requiredCondition:
      Boolean(
        watchedFields && watchedFields.emergencyContact?.homeTelephoneNo
      ) && "This is a required field",
  },
  {
    name: "emergencyContact.relationShip",
    label: "Relationship *",
    type: FieldType.Input,
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    placeholder: "Manager",
    defaultValue: staffProfile.emergencyContact?.relationShip,
    requiredCondition: "This is a required field",
  },
];
export const editContactDetails = (
  getValue: UseFormGetValues<FormData>,
  setValue: UseFormSetValue<FormData>,
  user: User,
  setResidentialState: (value: string | undefined) => void,
  residentialState: string | undefined,
  watchedFields: FormData
) => [
  {
    name: "mobile",
    label: "Mobile *",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: user.mobile,
    validationFunction: validateAustralianPhoneNumber,
    validationErrorMessage: "Invalid Australian phone number",
    requiredCondition: "This is a required field",
  },
  {
    name: "homePhone",
    label: "Home Telephone no",
    type: FieldType.Input,
    placeholder: "+614XXX XXX XXX",
    parentFormContainerClassName:
      "w-full md:w-1/3  inline-block align-top pr-2",
    defaultValue: user?.staffProfile?.homePhone,
    validationFunction: validateAustralianTelephoneNumber,
    validationErrorMessage: "Invalid Australian telephone number",
    requiredCondition:
      Boolean(watchedFields && watchedFields.homePhone) &&
      "This is a required field",
    additionalTriggerRequired: true,
  },
  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "",
    children: (
      <p
        className={classNames(
          "mt-5 text-sm leading-5 font-semibold text-gray-700"
        )}
      >
        Residential address *
      </p>
    ),
  },
  {
    name: "residentialAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: user.staffProfile.residentialAddressBuildingNo,
  },
  {
    name: "residentialAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: user.staffProfile.residentialAddressStreetName,
  },
  {
    name: "residentialAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: user.staffProfile.residentialAddressSuburb,
  },
  {
    name: "residentialAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "Select State/Territory",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: user.staffProfile.residentialAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "residentialAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: user.staffProfile.residentialAddressZip,
  },
  {
    type: FieldType.Paragraph,
    parentFormContainerClassName: "w-full",
    name: "",
    label: "",
    children: (
      <div className="flex space-x-4 items-center mt-5 ">
        <p
          className={classNames(
            "text-sm leading-5 font-semibold text-gray-700"
          )}
        >
          Mailing address *
        </p>
        <Checkbox
          name="sameAsResidentialAddress"
          label="Same as Residential address"
          className="!mt-0"
          type={FieldType.Checbox}
          onChange={(e) => {
            if (e.target.checked) {
              setValue(
                "mailingAddressBuildingNo",
                getValue("residentialAddressBuildingNo")
              );
              setValue(
                "mailingAddressStreetName",
                getValue("residentialAddressStreetName")
              );
              setValue(
                "mailingAddressSuburb",
                getValue("residentialAddressSuburb")
              );
              setValue(
                "mailingAddressState",
                getValue("residentialAddressState")
              );
              setResidentialState(getValue("residentialAddressState"));
              setValue("mailingAddressZip", getValue("residentialAddressZip"));
            } else {
              setValue("mailingAddressBuildingNo", "");
              setValue("mailingAddressStreetName", "");
              setValue("mailingAddressSuburb", "");
              setValue("mailingAddressState", "");
              setValue("mailingAddressZip", "");
              setResidentialState("");
            }
          }}
        />
      </div>
    ),
  },
  {
    name: "mailingAddressBuildingNo",
    label: "Building/House/Unit number",
    type: FieldType.Input,
    placeholder: "2",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: user.staffProfile.mailingAddressBuildingNo,
  },
  {
    name: "mailingAddressStreetName",
    label: "Street name",
    type: FieldType.Input,
    placeholder: "Rose Street",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: user.staffProfile.mailingAddressStreetName,
  },
  {
    name: "mailingAddressSuburb",
    label: "Suburb",
    type: FieldType.Input,
    placeholder: "Clayton",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: user.staffProfile.mailingAddressSuburb,
  },
  {
    name: "mailingAddressState",
    label: "State/Territory",
    type: FieldType.Select,
    placeholder: "Select State/Territory",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: residentialState || user.staffProfile.mailingAddressState,
    options: AUSTRALIA_STATE_OPTIONS,
  },
  {
    name: "mailingAddressZip",
    label: "Postcode",
    type: FieldType.Input,
    placeholder: "3001",
    parentFormContainerClassName:
      "md:w-1/3 w-full inline-block align-top md:pr-6",
    containerClassName: "w-full",
    className: "w-full",
    requiredCondition: "This is a required field",
    defaultValue: user.staffProfile.mailingAddressZip,
  },
];
export const editTraining = (staffProfile: StaffProfile) => [
  {
    name: "resume",
    label: "Upload resume",
    type: FieldType.FileUpload,
    defaultValue: staffProfile.resume,
    isMultipleFiles: false,
    buttonLabel: "Upload resume",
    parentFormContainerClassName: "w-full inline-block align-top pr-1",
    requiredCondition: "This is a required field",
  },
];
