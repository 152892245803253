import { Staff } from "api/types";
import { useGetStaff } from "api/user";
import Button from "components/Button";
import { Icon } from "components/Icon";
import Notification from "components/Notification";
import Pagination from "components/Pagination";
import RightDrawer from "components/RightDrawer";
import { SortOptions } from "components/SortableTable";
import { getAvatarName } from "components/StaffDetail";
import StickyTable, { StickyTableColumn } from "components/StickyTable";
import { FormData } from "components/types";
import UserDetailsContent from "components/UserDetailsContent";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { handleViewProfile } from "routes/dashboard/ExpiringDocuments";
import { StaffFilters } from "routes/dashboard/staff/AllStaff";
import { downloadFile } from "utils/file";
import { cleanObject, formatDate } from "utils/util";
import { UnionTypesForStaff } from "./EmploymentChecksRegister";

const columns: StickyTableColumn<Staff>[] = [
  {
    title: "Name",
    group: "Personal Details",
    dataIndex: "name",
    sortable: true,
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.firstName ? (
        <div
          className="flex items-center justify-start w-full cursor-pointer"
          onClick={() => handleViewProfile(row.id)}
        >
          <Avatar
            name={getAvatarName(row)}
            size="32"
            round
            textSizeRatio={2.28}
            className="!h-8 !w-8 !text-sm !leading-6 !font-medium !text-primary-900"
            color="#D1F5FC"
            fgColor="#194860"
            src={row.avatar}
          />
          <div className="flex flex-col items-start ml-4 w-36">
            <span className="underline text-sm leading-5 font-semibold text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap text-left w-36">{`${row.firstName} ${row.lastName}`}</span>
          </div>
        </div>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  { title: "e-mail", group: "Personal Details", dataIndex: "email" },
  {
    title: "mobile",
    group: "Personal Details",
    dataIndex: "mobile",
    columnStyle: "border-r border-gray-300",
    Cell: (value: UnionTypesForStaff, row: Staff) => (
      <span className="text-sm leading-5 font-normal text-gray-700">
        {row.mobile ? row.mobile : "N/A"}
      </span>
    ),
  },
  {
    title: "Document",
    dataIndex: "policeCheck",
    group: "Police check",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.policeCheck ? (
        <p
          className="text-sm leading-5 font-medium text-primary-600 cursor-pointer"
          onClick={() => downloadFile(row.staffProfile?.policeCheck)}
        >
          View
        </p>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
  {
    title: "Expiry date",
    group: "Police check",
    dataIndex: "policeCheckValidUntil",
    Cell: (value: UnionTypesForStaff, row: Staff) =>
      row.staffProfile?.policeCheckValidUntil ? (
        <span className="text-sm leading-5 font-normal text-gray-900">
          {formatDate(new Date(row.staffProfile?.policeCheckValidUntil))}
        </span>
      ) : (
        <span className="text-sm leading-5 font-normal text-gray-700">N/A</span>
      ),
  },
];

export default function PoliceCheckReport() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(10);
  const [isError, setIsError] = useState(false);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [sortConfig, setSortConfig] = useState<SortOptions<Staff> | null>(null);
  const navigate = useNavigate();

  const defaultFilter: Partial<StaffFilters> = {
    take: "10",
    page: "1",
  };
  const [filters, setFilters] = useState({ ...defaultFilter });
  const { handleSubmit, setValue, getValues, watch, reset, ...formMethods } =
    useForm<FormData>();

  const { data, isFetching } = useGetStaff(
    true,
    cleanObject({
      ...filters,
    })
  );
  useEffect(() => {
    if (data) {
      if (Array.isArray(data.staffs) && data.staffs) {
        setStaff(data.staffs);
        setTotalItems(data.count);
        return;
      }
      setIsError(true);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (sortConfig) {
      setFilters({
        page: "1",
        take: itemsPerPage.toString(),
        order_by: "firstName",
        order: sortConfig.sortOrder,
      });
      setCurrentPage(1);
    }
  }, [sortConfig, itemsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilters({ ...filters, page: page.toString() });
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    setFilters({ ...filters, page: (currentPage + 1).toString() });
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    setFilters({ ...filters, page: (currentPage - 1).toString() });
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to the first page whenever items per page change
    setFilters({ ...filters, page: "1", take: newItemsPerPage.toString() });
  };

  if (isError) {
    return (
      <Notification
        type="error"
        message={"Error in fetching data from server"}
      />
    );
  }
  return (
    <div className="">
      <h2 className="text-xl leading-7 font-semibold">Police Check Report</h2>
      <div className="mt-6 shadow-md rounded-md border border-gray-200">
        <StickyTable<Staff>
          columns={columns}
          data={staff}
          setSortConfig={setSortConfig}
          sortConfig={sortConfig}
          containerClassName="rounded-t-md"
        />
        <RightDrawer
          isOpen={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            setSelectedStaff(null);
          }}
          title="User Details"
        >
          {selectedStaff && <UserDetailsContent staff={{ ...selectedStaff }} />}
        </RightDrawer>
        <div className=" px-6 pb-4 ">
          <FormProvider
            {...{
              ...formMethods,
              handleSubmit,
              setValue,
              getValues,
              reset,
              watch,
            }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
            />
          </FormProvider>
        </div>
      </div>

      <div className="mt-3 py-3 border-t border-gray-200">
        <Button
          variant="gray"
          onClick={() => navigate(`/dashboard/staff`)}
          className="flex justify-center items-center py-3 pl-3"
          size="noSize"
        >
          <Icon name="back" />
          <span className="ml-2 text-gray-500 text-sm mr-3 font-semibold">
            Go back to Staff dashboard
          </span>
        </Button>
      </div>
    </div>
  );
}
