import { user } from "api";
import { AxiosError } from "axios";
import { useState } from "react";
import Dialogue from "./Dialogue";
import { Icon } from "./Icon";
import Input from "./Input";

interface ForgotUsernameProps {
  openDialogue: boolean;
  setOpenDialogue: (open: boolean) => void;
}

export default function ForgotUsername({
  openDialogue,
  setOpenDialogue,
}: ForgotUsernameProps) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<{
    email: string | null;
  }>({
    email: null,
  });
  const [showHelperText, setShowHelperText] = useState(false);

  const handleForgotUsername = async () => {
    if (!email || email.trim() === "") {
      setError({
        ...error,
        email: "Email is required",
      });
      return;
    }
    try {
      await user.forgotUsername(email); // needs to be updated
      setShowHelperText(true);
    } catch (err) {
      console.log(err);
      const errMessage =
        ((err as AxiosError).response?.data as {
          validation: { body: { message: string } };
          message: string;
        }) ||
        ((err as AxiosError).response?.data as {
          message: string;
        });
      setError({
        ...error,
        email: errMessage?.validation?.body?.message || errMessage?.message,
      });
      setShowHelperText(false);
    }
  };
  return (
    <Dialogue
      key={"forgotUsername"}
      title="Forgot your Username? It happens!"
      confirmButtonLabel="Send username"
      panelClassName="!w-[40rem]"
      cancelButtonLabel="Cancel"
      containerClassName="!pb-2"
      children={
        <div className="pt-3">
          <Input
            label="What's your email address?"
            type="text"
            placeholder="john@doe.com"
            labelClassName="font-semibold"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError({ ...error, email: null });
            }}
            error={error.email}
          />
          {showHelperText && (
            <p className="text-sm leading-5 font-normal text-green-700 mt-1">
              We have sent you a username reminder if your email exists in our
              system!
            </p>
          )}
        </div>
      }
      open={openDialogue}
      handleClose={() => {
        setOpenDialogue(false);
        setShowHelperText(false);
        setEmail("");
        setError({
          ...error,
          email: null,
        });
      }}
      handleConfirm={handleForgotUsername}
      iconElement={
        <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-12 sm:w-12 my-4">
          <Icon name="lock" className="h-6 w-6" />
        </div>
      }
    />
  );
}
